export enum AppointmentStatus {
  Scheduled = 'Scheduled',
  CheckedIn = 'Checked in',
  ReadyForDoctor = 'Ready for doctor',
  CheckedOut = 'Checked out',
  Cancelled = 'Cancelled',
  Held = 'Held',
}

export interface Appointment {
  providerId: string;
  startDate: number;
  endDate?: number;
  patient?: Patient;
  reason?: string;
  appointmentStatus: AppointmentStatus;
  insertDate?: string;
}

export interface ProgressNoteProps {
  progressNote: Note;
  changeProgressNote: (values: Partial<Note>) => void;
}

export interface Note {
  patientId?: string;
  noteId: string;
  hpiShort: string;
  hpi: string;
  medications?: Medication[];
  allergies?: Allergy[];
  pastMedicalHistories?: PastMedicalHistory[];
  pastSurgicalHistories?: PastSurgicalHistory[];
  reviewOfSystems?: ReviewOfSystems;
  vitalSigns?: VitalSigns;
  familyHistory?: FamilyHistory[];
  socialHistory?: SocialHistory[];
  physicalExam?: PhysicalExam;
  problems?: Problem[];
  returnOn?: string;
  levelOfService?: LevelOfService;
  insertDate?: string;
  insertUser: User;
}

export interface Problem {
  problem: string;
  icdCode?: string;
  insertDate?: string;
  insertUser: User;
  assessmentMedications?: Medication[];
  assessmentTextInstructions?: string[];
}

export interface Provider {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  phoneNumber?: string;
  fax?: string;
  npi?: string;
  role?: string;
}

export interface ProviderSignupRequest extends Provider {
  password: string;
}

export interface ProviderSignupForm extends Provider {
  password: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface Patient {
  patientId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  nameSuffix?: string;
  gender: string;
  dateOfBirth: string;
  mobilePhoneCountry: string;
  mobilePhone: string;
  homeNumber?: string;
  workNumber?: string;
  profilePicture?: string;
  contactPreference?: string;
  email: string;
  emergencyContactRelation?: string;
  emergencyContactNumber?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  insuranceProvider: string;
  groupNumber: string;
  memberId: string;
  insuranceCardFrontBlobId?: string;
  insuranceCardBackBlobId?: string;
  pharmacy?: string;
  insertDate: string;
  providerId: string;
}

export interface Medication {
  medicationName: string;
  sig?: string;
  qty?: number;
  unit?: string;
  refills?: number;
  diagnosis?: string;
  daw?: boolean;
  earliestFillDate?: string;
  notesToPharmacy?: string;
  notesToPatient?: string;
  allergicReactions?: string;
  insertDate?: string;
}

export enum AllergySeverity {
  HIGH,
  MODERATE,
  MILD,
}

export interface PastMedicalHistory {
  icdCode?: string;
  icdValue: string;
  icdLink?: string;
  insertDate: string;
}

export interface Allergy {
  allergy: string;
  severity: AllergySeverity;
  symptoms: string;
  insertDate: string;
}

export interface PastSurgicalHistory {
  surgery: string;
  date: string;
  comment?: string;
  insertDate: string;
}

export interface FamilyHistory {
  familyHistory: string;
  enteredBy: User;
  insertDate: string;
}

export interface SocialHistory {
  socialHistory: string;
  enteredBy: User;
  insertDate: string;
}

export interface ReviewOfSystems {
  constitution?: string[];
  hent?: string[];
  eyes?: string[];
  cardio?: string[];
  respiratory?: string[];
  gi?: string[];
  gu?: string[];
  muscle?: string[];
  skin?: string[];
  heme?: string[];
  immuno?: string[];
  endocrine?: string[];
  neuro?: string[];
  psych?: string[];
  comment?: string;
}

export enum TemperatureDegree {
  F,
  C,
}

export interface VitalSignTemp {
  temperature: number;
  degree: TemperatureDegree;
  selected: string;
}

export interface VitalSignHeartRate {
  rate: number;
  selected: string;
}

export interface RespirationRate {
  rate?: number;
  selected?: string;
}

export interface BloodPressure {
  pressure: string;
  selected: string[];
}

export interface Height {
  height: number;
  selected: string[];
}

export interface Weight {
  weight: number;
  selected: string[];
}

export interface Oxygenation {
  oxygenation: number;
  selected: string;
}

export interface HeadCircumference {
  headCircumference: number;
  selected: string;
}

export interface VisionOd {
  withCorrect?: boolean;
  selected?: string;
}

export interface VisionOs {
  withCorrect?: boolean;
  selected?: string;
}

export interface VitalSigns {
  temperature?: VitalSignTemp;
  heartRate?: VitalSignHeartRate;
  respirationRate?: RespirationRate;
  bloodPressure?: BloodPressure[];
  height?: Height;
  weight?: Weight;
  oxygenation?: Oxygenation;
  visionOd?: VisionOd;
  visionOs?: VisionOs;
  headCircumference?: HeadCircumference;
}

export interface PhysicalExam {
  constitution?: string[];
  hent?: string[];
  cardioVascular?: string[];
  acuteDistress?: string;
  rightEar?: string[];
  leftEar?: string[];
  nose?: string[];
  mouthThroat?: string[];
  eyes?: string[];
  neck?: string[];
  cardioRate?: string[];
  cardioRhythm?: string[];
  pulsesHeartSound?: string[];
  pulmonary?: string[];
  abdominal?: string[];
  genitourinaryAnorectal?: string[];
  musculoskeletal?: string[];
  skin?: string[];
  neurologic?: string[];
  psychiatric?: string[];
  comment?: string;
}

export interface LevelOfService {
  em?: string;
  modifiers?: string;
  emCodes?: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string;
  isPatient?: boolean;
}
