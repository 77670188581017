import { Avatar, Collapse } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { format } from 'date-fns';
import {
  calculateAgebyBirthdate,
  formatPhoneNumber,
  generateRandomColorFromString,
  getInitials,
} from '../../common/functions';
import { Patient } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type PatientInfoContainerProps = {
  patient: Patient | null;
};

const defaultDob = new Date().toISOString();

const PatientInfoContainer: React.FC<PatientInfoContainerProps> = ({ patient }) => {
  const medicationsSummary = useSelector((state: RootState) => state.note.medicationsSummary ?? []);
  const allergiesSummary = useSelector((state: RootState) => state.note.allergiesSummary ?? []);
  const pastMedicalHistoriesSummary = useSelector((state: RootState) => state.note.pastMedicalHistoriesSummary ?? []);
  const pastSurgicalHistoriesSummary = useSelector((state: RootState) => state.note.pastSurgicalHistoriesSummary ?? []);
  const familyHistorySummary = useSelector((state: RootState) => state.note.familyHistorySummary ?? []);
  const socialHistorySummary = useSelector((state: RootState) => state.note.socialHistorySummary ?? []);
  const problems = useSelector((state: RootState) => state.note.problems ?? []);

  return (
    <div className="flex flex-col h-full w-2/5 xl:w-1/3">
      <div className="flex flex-col w-full background-primary-300 py-5 px-2 lg:px-6 gap-5">
        <div className="flex justify-center gap-5">
          <Avatar
            sx={{
              width: 96,
              height: 96,
              bgcolor: generateRandomColorFromString(patient!.email),
            }}
            alt={patient?.firstName || ''}
          >
            {getInitials(patient!.firstName, patient!.lastName)}
          </Avatar>
          <div className="flex flex-col h-full justify-center">
            <div className="font-bold text-2xl">{`${patient?.firstName || ''} ${patient?.lastName || ''}`}</div>
            <p className="text-sm text-gray-600">{`${patient?.gender || ''} / ${calculateAgebyBirthdate(
              patient ? patient.dateOfBirth : defaultDob
            )}`}</p>
            <p className="text-sm text-gray-600">
              DOB: {format(new Date(patient ? patient.dateOfBirth || defaultDob : defaultDob), 'MM/dd/yyyy')}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-3">
            <p>Insurance:</p>
            <p className="text-blue-400 col-span-2">{patient?.insuranceProvider}</p>
          </div>
          <div className="flex gap-3">
            <PhoneInTalkOutlinedIcon />
            <p>{formatPhoneNumber(patient?.mobilePhone)}</p>
          </div>
          <div className="flex gap-3">
            <FmdGoodOutlinedIcon />
            <p>{patient?.address1}</p>
          </div>
          <div className="grid grid-cols-3">
            <p>Pharmacy:</p>
            <p className="text-blue-400 col-span-2">{patient?.pharmacy}</p>
          </div>
          {/* <div className="grid grid-cols-3">
            <p>Laboratory:</p>
            <p className="text-blue-400 col-span-2">{patient?.laboratory}</p>
          </div>
          <div className="grid grid-cols-3">
            <p>Imaging:</p>
            <p className="text-blue-400 col-span-2">{patient?.imaging}</p>
          </div> */}
        </div>
      </div>

      <div className="background-primary-100 py-6 px-2 lg:px-5 gap-5 flex flex-col h-full">
        <PatientDetail title="Problems" items={problems.map((p) => p.problem)} />
        <PatientDetail title="Medications" items={medicationsSummary} />
        <PatientDetail title="Allergies" items={allergiesSummary} />
        <PatientDetail title="Past Medical History" items={pastMedicalHistoriesSummary} />
        <PatientDetail title="Past Surgical History" items={pastSurgicalHistoriesSummary} />
        <PatientDetail title="Family History" items={familyHistorySummary} />
        <PatientDetail title="Social History" items={socialHistorySummary} />
      </div>
    </div>
  );
};

function PatientDetail({ title, items }: { title: string; items: string[] }) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div onClick={() => setOpen(!open)}>
      <div className="flex justify-between">
        <p className="font-bold">
          {title} ({items.length})
        </p>
        <KeyboardArrowDownIcon className={`${open ? 'rotate-180' : 'rotate-0'}`} />
      </div>
      <Collapse in={open} unmountOnExit>
        <ul className="list-disc list-inside">
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
}

export default PatientInfoContainer;
