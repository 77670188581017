import { TextField, FormControl, Select, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { storePatient, uploadBlob } from '../../api';
import { Patient } from '../../types';
import { PatientContext } from '../../context/PatientContext';
import Header from '../topbar/Header';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrimaryButton from '../custom/PrimaryButton';
import { toast } from 'react-toastify';
import { allStates } from '../common/Consts';
import { formatPhoneNumber } from '../common/functions';
import Loader from '../common/Loadert';

const initPatientState = {
  patientId: '',
  firstName: '',
  lastName: '',
  middleName: '',
  nameSuffix: '',
  gender: '',
  dateOfBirth: '',
  mobilePhoneCountry: '+1',
  mobilePhone: '',
  homeNumber: '',
  workNumber: '',
  profilePicture: '',
  contactPreference: '',
  email: '',
  emergencyContactRelation: '',
  emergencyContactNumber: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  insuranceProvider: '',
  groupNumber: '',
  memberId: '',
  insuranceCardFrontBlobId: '',
  insuranceCardBackBlobId: '',
  insertDate: '',
  providerId: '',
  pharmacy: '',
};

const AddPatientForm: React.FC = () => {
  const { state } = useLocation();
  const { patients, setPatients } = useContext(PatientContext);
  const [insuranceFront, setInsuranceFront] = useState<File | null>(null);
  const [insuranceBack, setInsuranceBack] = useState<File | null>(null);
  const [patient, setPatient] = useState<Patient>(state?.patient ?? initPatientState);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const savePatient = async () => {
    if (
      !patient.firstName ||
      !patient.lastName ||
      !patient.gender ||
      !patient.dateOfBirth ||
      !patient.mobilePhone ||
      !patient.address1 ||
      !patient.city ||
      !patient.state ||
      !patient.zipCode ||
      !patient.pharmacy ||
      !patient.insuranceProvider ||
      !patient.groupNumber ||
      !patient.memberId ||
      !insuranceFront ||
      !insuranceBack
    ) {
      toast.error('Please fill required fields in blue.');
      return;
    }
    setLoading(true);
    patient.insuranceCardFrontBlobId = (await uploadBlob(insuranceFront!, 'insurance')) || '';
    patient.insuranceCardBackBlobId = (await uploadBlob(insuranceBack!, 'insurance')) || '';
    const isSuccess = await storePatient(patient);
    if (isSuccess) {
      const newPatients = [...patients];
      newPatients.unshift(patient);
      setPatients(newPatients);
      setPatient(initPatientState);
      setInsuranceBack(null);
      setInsuranceFront(null);
      toast.success('Successfully registered the patient');
      navigate('/patients');
    } else {
      toast.error('Error while creating a patient. Please contact support.');
    }
    setLoading(false);
  };

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const copy: any = { ...patient };
    copy[e.target.name] = e.target.value;
    setPatient(copy);
  }

  return (
    <div className="flex flex-col w-full h-full background-primary-100">
      <Header />
      <div className="flex flex-col p-5">
        <h2 className="text-primary-700 text-xl font-bold">Patient Registration</h2>
        <div className="grid grid-cols-2 mt-5 gap-2">
          <div className="flex flex-col">
            <SectionHeader text="Identification" />
            <div className="flex flex-col py-3 gap-2">
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Legal Last Name</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="lastName"
                  value={patient.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Legal First Name</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="firstName"
                  value={patient.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Middle Name/Suffix</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="middleName"
                  value={patient.middleName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
                <TextField
                  className="bg-white w-1/2"
                  sx={{ fontSize: '1em' }}
                  name="nameSuffix"
                  value={patient.nameSuffix}
                  placeholder="Suffix"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Legal Sex</label>
                <FormControl fullWidth>
                  <Select
                    name="gender"
                    className="background-primary-300"
                    value={patient.gender}
                    onChange={(e) =>
                      setPatient({
                        ...patient,
                        gender: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Date of Birth</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="background-primary-300"
                    value={patient.dateOfBirth ? new Date(patient.dateOfBirth) : null}
                    onChange={(date: Date | null) => {
                      if (date !== null) {
                        setPatient({ ...patient, dateOfBirth: date.toISOString() });
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <SectionHeader text="Address" />
            <div className="flex flex-col py-3 gap-2">
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Address 1</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="address1"
                  value={patient.address1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Address 2</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="address2"
                  value={patient.address2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>City</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="city"
                  value={patient.city}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>State</label>
                <FormControl>
                  <Select
                    name="state"
                    className="background-primary-300"
                    value={patient.state}
                    onChange={(e) =>
                      setPatient({
                        ...patient,
                        state: e.target.value,
                      })
                    }
                  >
                    {allStates.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Zip Code</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="zipCode"
                  value={patient.zipCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length < 6 && (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value))) {
                      onChange(e);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <SectionHeader text="Contact" />
            <div className="flex flex-col py-3 gap-2">
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Mobile Number</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="mobilePhone"
                  value={formatPhoneNumber(patient.mobilePhone)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                    const phoneNumberWithoutFormat = formattedPhoneNumber
                      .replace('-', '')
                      .replace('(', '')
                      .replace(')', '')
                      .replace(' ', '');
                    setPatient({
                      ...patient,
                      mobilePhone: phoneNumberWithoutFormat,
                    });
                  }}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Home Number</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="homeNumber"
                  value={formatPhoneNumber(patient.homeNumber)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                    const phoneNumberWithoutFormat = formattedPhoneNumber
                      .replace('-', '')
                      .replace('(', '')
                      .replace(')', '')
                      .replace(' ', '');
                    setPatient({
                      ...patient,
                      homeNumber: phoneNumberWithoutFormat,
                    });
                  }}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Work Number</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="workNumber"
                  value={formatPhoneNumber(patient.workNumber)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                    const phoneNumberWithoutFormat = formattedPhoneNumber
                      .replace('-', '')
                      .replace('(', '')
                      .replace(')', '')
                      .replace(' ', '');
                    setPatient({
                      ...patient,
                      workNumber: phoneNumberWithoutFormat,
                    });
                  }}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Contact Preference</label>
                <FormControl>
                  <Select
                    name="contactPreference"
                    className="bg-white"
                    value={patient.contactPreference}
                    onChange={(e) =>
                      setPatient({
                        ...patient,
                        contactPreference: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={'Home Number'}>Home Number</MenuItem>
                    <MenuItem value={'Mobile Number'}>Mobile Number</MenuItem>
                    <MenuItem value={'Work Number'}>Work Number</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Patient Email</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="email"
                  value={patient.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Emergency Contact Relation</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="emergencyContactRelation"
                  value={patient.emergencyContactRelation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Emergency Contact Number</label>
                <TextField
                  className="bg-white"
                  sx={{ fontSize: '1em' }}
                  name="emergencyContactNumber"
                  value={patient.emergencyContactNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h4 className="text-primary-700 text-lg font-semibold border-b border-gray-400">Insurance & Pharmacy</h4>
            <div className="flex flex-col py-3 gap-2">
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Pharmacy</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="pharmacy"
                  value={patient.pharmacy}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Insurance Provider</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="insuranceProvider"
                  value={patient.insuranceProvider}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Group Number</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="groupNumber"
                  value={patient.groupNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Member ID Number</label>
                <TextField
                  className="background-primary-300"
                  sx={{ fontSize: '1em' }}
                  name="memberId"
                  value={patient.memberId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 items-center">
                <label>Insurance Card Photo</label>
                <div className="col-span-2 flex gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="insuranceFront" className="cursor-pointer">
                      {insuranceFront ? (
                        <img
                          src={URL.createObjectURL(insuranceFront)}
                          alt="insuranceFront "
                          className="border bg-white rounded border-gray-400 w-32 h-32 object-cover"
                        />
                      ) : (
                        <div
                          className="border background-primary-300 rounded border-gray-400 w-32 h-32
                         flex justify-center items-center"
                        >
                          +
                        </div>
                      )}
                      <input
                        className="hidden"
                        type="file"
                        accept="image/*"
                        id="insuranceFront"
                        onChange={(e: any) => {
                          setInsuranceFront(e.target.files![0]);
                        }}
                      />
                    </label>
                    <center>Front of Card</center>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="insuranceBack" className="cursor-pointer">
                      {insuranceBack ? (
                        <img
                          src={URL.createObjectURL(insuranceBack)}
                          alt="insuranceBack "
                          className="border bg-white rounded border-gray-400 w-32 h-32 object-cover"
                        />
                      ) : (
                        <div
                          className="border background-primary-300 rounded border-gray-400 w-32 h-32
                         flex justify-center items-center"
                        >
                          +
                        </div>
                      )}
                      <input
                        className="hidden"
                        type="file"
                        accept="image/*"
                        id="insuranceBack"
                        onChange={(e: any) => {
                          setInsuranceBack(e.target.files![0]);
                        }}
                      />
                    </label>
                    <center>Back of Card</center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="flex flex-col">{loading && <Loader />}</div>
          <div className="flex gap-5">
            <div>
              <PrimaryButton className=" px-6 py-1 background-primary-500" onClick={() => navigate('/patients')}>
                <span className="text-black">Cancel</span>
              </PrimaryButton>
            </div>
            <div>
              <PrimaryButton
                className={`${loading ? 'cursor-not-allowed opacity-50' : ''}  px-6 py-1 background-primary-500`}
                onClick={() => {
                  if (!loading) savePatient();
                }}
              >
                <span className="text-black">Register</span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function SectionHeader({ text }: { text: string }) {
  return <h4 className="text-primary-700 text-lg font-semibold border-b border-gray-400">{text}</h4>;
}

export default AddPatientForm;
