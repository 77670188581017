import { useState } from 'react';
import { ProgressNoteProps } from '../../../types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';

const ProgressNoteFollowUp: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [field, setField] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <ProgressNoteCollapse name="Follow Up">
      <>
        <div className="grid grid-cols-7 background-primary-100 p-4 gap-2">
          <div className="col-span-2 flex flex-col">
            <label>Return on</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                onChange={(date: Date | null) => {
                  if (date != null) {
                    setField('');
                    setSelectedDate(date);
                    changeProgressNote({
                      ...progressNote,
                      returnOn: date.toISOString(),
                    });
                  }
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="flex flex-col col-span-5">
            <label>Return in</label>
            <div className="grid grid-cols-6 gap-2">
              {['2 weeks', '4 weeks', '3 months', '4 months', '6 months', '1 year'].map((value) => {
                return (
                  <button
                    key={value}
                    className={`radioButton ${
                      progressNote.returnOn === value ? 'background-primary-500' : 'background-primary-100'
                    } `}
                    onClick={() => {
                      setSelectedDate(null);
                      setField('');
                      changeProgressNote({
                        ...progressNote,
                        returnOn: value,
                      });
                    }}
                  >
                    {value}
                  </button>
                );
              })}

              <input
                type="number"
                className="bg-white py-2 px-2 rounded border border-gray-400"
                style={{
                  maxWidth: '150px',
                }}
                value={field}
                onChange={(e) => {
                  changeProgressNote({
                    ...progressNote,
                    returnOn: '',
                  });
                  setSelectedDate(null);
                  setField(e.target.value);
                }}
              />
              {['Days', 'Weeks', 'Months', 'Years'].map((value) => {
                return (
                  <button
                    key={value}
                    className={`radioButton ${
                      progressNote.returnOn?.includes(value) ? 'background-primary-500' : 'background-primary-100'
                    } `}
                    onClick={() => {
                      if (!field) {
                        toast.error('Enter value in field');
                        return;
                      }
                      changeProgressNote({
                        ...progressNote,
                        returnOn: field + ' ' + value,
                      });
                    }}
                  >
                    {value}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteFollowUp;
