import { Box, Stepper, Typography, Step, StepLabel, Button } from '@mui/material';
import React, { useState } from 'react';
import ProviderBasicInfoForm from './ProviderBasicInfoForm';
import ProviderVerification from './ProviderVerification';
import { createProvider } from '../../../api';
import { ProviderSignupForm, ProviderSignupRequest } from '../../../types';
import ProviderImage from '../../../assets/ProviderLoginImage.png';
import GuestTopBar from '../../common/GuestTopBar';
import { toast } from 'react-toastify';
import Copyright from '../../common/Copyright';
import useWindowResize from '../../../hooks/useWindowResize';
import { passwordValidator } from '../../common/functions';

export const providerRegistrationSteps = ['Basic Info', 'Verification'];

const ProviderRegistration: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { windowWidth } = useWindowResize();
  const isSmallScreen = windowWidth < 800;
  const [basicInfo, setBasicInfo] = useState<ProviderSignupForm>({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    npi: '',
    phoneNumber: '',
    fax: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    password: '',
  });

  const handleNext = () => {
    const { isLengthValid, hasUppercase, hasLowercase, hasSpecialChar } = passwordValidator(basicInfo.password);

    if (
      !basicInfo.firstName ||
      !basicInfo.lastName ||
      !basicInfo.email ||
      !basicInfo.phoneNumber ||
      !basicInfo.npi ||
      !basicInfo.address1 ||
      !basicInfo.city ||
      !basicInfo.state ||
      !basicInfo.zipCode ||
      !basicInfo.password ||
      !isLengthValid ||
      !hasUppercase ||
      !hasLowercase ||
      !hasSpecialChar
    ) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (basicInfo.email && basicInfo.npi) saveProviderBasicInfoForm(basicInfo);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const saveProviderBasicInfoForm = async (providerBasicInfo: ProviderSignupForm) => {
    const provider: ProviderSignupRequest = {
      firstName: providerBasicInfo.firstName,
      lastName: providerBasicInfo.lastName,
      role: providerBasicInfo.role,
      email: providerBasicInfo.email,
      npi: providerBasicInfo.npi,
      // phoneNumber: providerBasicInfo.phoneNumber, phone number must be in the following format
      phoneNumber: '+1' + providerBasicInfo.phoneNumber,
      fax: providerBasicInfo.fax,
      address: `${providerBasicInfo.address1}, ${providerBasicInfo.address2 ? providerBasicInfo.address2 + ', ' : ''}${
        providerBasicInfo.city
      }, ${providerBasicInfo.state} ${providerBasicInfo.zipCode}`,
      password: providerBasicInfo.password,
    };
    await createProvider(provider);
  };

  const getStepToDisplay = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <ProviderBasicInfoForm providerBasicInfo={basicInfo} setBasicInfoCallback={setBasicInfo} />;
      case 1:
        return <ProviderVerification emailProp={basicInfo.email} password={basicInfo.password} />;
      default:
        return <>Error Step</>;
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          padding: '1em',
        }}
      >
        {isSmallScreen ? (
          <></>
        ) : (
          <div style={{ display: 'flex' }}>
            <img src={ProviderImage} alt={'ProviderImage'} style={{ height: '85vh', objectFit: 'cover' }} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            paddingLeft: '.5em',
          }}
        >
          <GuestTopBar selectedOption={'provider'} />
          <div className="flex flex-col mx-20">
            <h2>
              <center className="text-xl font-bold my-5">Provider Registration</center>
            </h2>
            <Stepper activeStep={activeStep}>
              {providerRegistrationSteps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === providerRegistrationSteps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{getStepToDisplay(activeStep)}</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: '.5em',
                  }}
                >
                  <div style={{ alignSelf: 'flex-start' }}>
                    <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                  </div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    <Button onClick={handleNext}>
                      {activeStep === providerRegistrationSteps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: '1em' }}>
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderRegistration;
