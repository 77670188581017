import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Allergy, AllergySeverity, ProgressNoteProps } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';

export const blankAllergy: Allergy = {
  allergy: '',
  severity: AllergySeverity.MODERATE,
  symptoms: '',
  insertDate: new Date().toISOString(),
};

const ProgressNoteAllergies: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [allergyToEdit, setAllergyToEdit] = useState<number | null>(null);
  const [currentAllergy, setCurrentAllergy] = useState<Allergy>({
    ...blankAllergy,
    insertDate: new Date().toISOString(),
  });

  const resetCurrentAllergy = () => {
    setCurrentAllergy({
      ...blankAllergy,
      insertDate: new Date().toISOString(),
    });
    setAllergyToEdit(null);
  };

  const addAllergy = () => {
    if (!currentAllergy.allergy) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    const prevState = progressNote.allergies ?? [];
    changeProgressNote({
      allergies: [...prevState, currentAllergy],
    });
    setDialogOpen(false);
    resetCurrentAllergy();
  };

  const editAllergy = () => {
    if (!currentAllergy.allergy) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    if (allergyToEdit != null) {
      let newData = progressNote.allergies ?? [];
      newData[allergyToEdit] = currentAllergy;
      changeProgressNote({
        allergies: newData,
      });
    }
    setDialogOpen(false);
    resetCurrentAllergy();
  };

  const removeAllergy = (index: number) => {
    let newData =
      progressNote.allergies?.filter((_, mIndex) => {
        return mIndex !== index;
      }) ?? [];
    changeProgressNote({
      allergies: newData,
    });
  };

  return (
    <ProgressNoteCollapse name="Allergies">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.allergies && progressNote.allergies.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border rounded border-gray-400">
              {progressNote.allergies?.map((allergy, index) => {
                return (
                  <li key={index} className="w-full">
                    <div className="flex justify-between w-full">
                      <div className="grid grid-cols-3 w-1/2">
                        <div>{allergy.allergy}</div>
                        <div className={`font-semibold ${getSeverityColor(allergy.severity)}`}>
                          {AllergySeverity[allergy.severity]}
                        </div>
                        <div>{allergy.symptoms}</div>
                      </div>
                      <div className="flex gap-2">
                        <button className="text-blue-500" onClick={() => removeAllergy(index)}>
                          remove
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="flex justify-end">
            <PrimaryButton
              onClick={() => {
                resetCurrentAllergy();
                setDialogOpen(true);
              }}
            >
              Add Allergy +
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen}>
          <div className="bg-primary-300">
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>{allergyToEdit !== null ? 'Edit Allergy' : 'Add Allergy'}</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col gap-5">
                <TextField
                  label="Allergy *"
                  variant="standard"
                  value={currentAllergy.allergy}
                  onChange={(e) => setCurrentAllergy({ ...currentAllergy, allergy: e.target.value })}
                  style={{
                    minWidth: '500px',
                  }}
                />
                <FormControl variant="standard" className="col-span-2">
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={currentAllergy.severity}
                    onChange={(e) =>
                      setCurrentAllergy({ ...currentAllergy, severity: e.target.value as AllergySeverity })
                    }
                  >
                    <MenuItem value={AllergySeverity.HIGH}>HIGH</MenuItem>
                    <MenuItem value={AllergySeverity.MILD}>MILD</MenuItem>
                    <MenuItem value={AllergySeverity.MODERATE}>MODERATE</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Symptoms"
                  variant="standard"
                  value={currentAllergy.symptoms}
                  onChange={(e) => setCurrentAllergy({ ...currentAllergy, symptoms: e.target.value })}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={allergyToEdit !== null ? editAllergy : addAllergy}>
                {allergyToEdit !== null ? 'Save Changes' : 'Add'}
              </PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

const getSeverityColor = (severity: AllergySeverity) => {
  switch (severity) {
    case AllergySeverity.HIGH:
      return 'text-red-500';
    case AllergySeverity.MILD:
      return 'text-orange-500';
    case AllergySeverity.MODERATE:
      return 'text-yellow-500';
    default:
      return '';
  }
};

export default ProgressNoteAllergies;
