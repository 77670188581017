import { ProgressNoteProps } from '../../../types';
import ProgressNoteCollapse from './ProgressNoteCollapse';

const ProgressNoteLevelOfService: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  return (
    <ProgressNoteCollapse name="Level of Service">
      <>
        <div className="flex background-primary-100 p-4 gap-2">
          <div className="flex flex-col col-span-2">
            <label>E&M</label>
            <input
              type="text"
              className="bg-white py-2 px-2 rounded border border-gray-400 "
              style={{
                maxWidth: '150px',
              }}
              value={progressNote.levelOfService?.em || ''}
              onChange={(e) => {
                changeProgressNote({
                  ...progressNote,
                  levelOfService: {
                    ...progressNote.levelOfService,
                    em: e.target.value,
                  },
                });
              }}
            />
            <label>Modifiers</label>
            <input
              type="text"
              className="bg-white py-2 px-2 rounded border border-gray-400"
              style={{
                maxWidth: '150px',
              }}
              value={progressNote.levelOfService?.modifiers || ''}
              onChange={(e) => {
                changeProgressNote({
                  ...progressNote,
                  levelOfService: {
                    ...progressNote.levelOfService,
                    modifiers: e.target.value,
                  },
                });
              }}
            />
            <label>Additional E&M Codes</label>
            <input
              type="text"
              className="bg-white py-2 px-2 rounded border border-gray-400"
              style={{
                maxWidth: '150px',
              }}
              value={progressNote.levelOfService?.emCodes || ''}
              onChange={(e) => {
                changeProgressNote({
                  ...progressNote,
                  levelOfService: {
                    ...progressNote.levelOfService,
                    emCodes: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="grid grid-cols-6 gap-2">
            {['Level 1 New', 'Level 2 New', 'Level 3 New', 'Level 4 New', 'Level 5 New'].map((value) => {
              return (
                <button
                  key={value}
                  className={`radioButton ${
                    progressNote.levelOfService?.em === value ? 'background-primary-500' : 'background-primary-100'
                  } `}
                  onClick={() => {
                    changeProgressNote({
                      ...progressNote,
                      levelOfService: {
                        ...progressNote.levelOfService,
                        em: value,
                      },
                    });
                  }}
                >
                  {value}
                </button>
              );
            })}

            {['Level 1 Est', 'Level 2 Est', 'Level 3 Est', 'Level 4 Est', 'Level 5 Est'].map((value) => {
              return (
                <button
                  key={value}
                  className={`radioButton ${
                    progressNote.levelOfService?.em === value ? 'background-primary-500' : 'background-primary-100'
                  } `}
                  onClick={() => {
                    changeProgressNote({
                      ...progressNote,
                      levelOfService: {
                        ...progressNote.levelOfService,
                        em: value,
                      },
                    });
                  }}
                >
                  {value}
                </button>
              );
            })}

            {['CPE 18-39', 'CPE 40-64', 'CPE > 65'].map((value) => {
              return (
                <button
                  key={value}
                  className={`radioButton ${
                    progressNote.levelOfService?.em === value ? 'background-primary-500' : 'background-primary-100'
                  } `}
                  onClick={() => {
                    changeProgressNote({
                      ...progressNote,
                      levelOfService: {
                        ...progressNote.levelOfService,
                        em: value,
                      },
                    });
                  }}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </div>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteLevelOfService;
