import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { ProgressNoteProps, PastMedicalHistory } from '../../../types';
import AddIcon from '@mui/icons-material/Add';
import ProgressNoteCollapse from './ProgressNoteCollapse';

const ProgressNotePastMedicalHistories: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [currentMedicalHistory, setCurrentMedicalHistory] = useState<PastMedicalHistory>({
    icdValue: '',
    insertDate: new Date().toISOString(),
  });

  return (
    <ProgressNoteCollapse name="Past Medical History">
      <>
        <div className="flex flex-col p-4 gap-2 background-primary-100">
          <div>
            <TextField
              label="Add Problem"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AddIcon
                      className="cursor-pointer"
                      onClick={() => {
                        if (!currentMedicalHistory.icdValue) return;
                        const prevState = progressNote.pastMedicalHistories ?? [];
                        changeProgressNote({
                          pastMedicalHistories: [...prevState, currentMedicalHistory],
                        });
                        setCurrentMedicalHistory({
                          icdValue: '',
                          insertDate: new Date().toISOString(),
                        });
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={currentMedicalHistory.icdValue}
              onChange={(e) => setCurrentMedicalHistory({ ...currentMedicalHistory, icdValue: e.target.value })}
            />
          </div>
          {progressNote.pastMedicalHistories && progressNote.pastMedicalHistories.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border bg-white rounded border-gray-400">
              {progressNote.pastMedicalHistories?.map((medicalHistory, index) => {
                return (
                  <li key={index} className="w-full">
                    <div className="text-blue-500">{medicalHistory.icdValue}</div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNotePastMedicalHistories;
