import styled from 'styled-components';
import { Medication, ReviewOfSystems } from '../../types';
import { ColorScheme } from '../../redux/slices/settingsSlice';

export const reviewOfSystems: Omit<ReviewOfSystems, 'comment' | 'insertDate'> = {
  constitution: ['Activity Change', 'Appetite Change', 'Chills', 'Diaphoresis', 'Fatigue', 'Fever', 'Weight Change'],
  hent: [
    'Congestion',
    'Dental Problem',
    'Drooling',
    'Ear Discharge',
    'Ear Pain',
    'Facial Swelling',
    'Hearing Loss',
    'Mouth Sores',
    'Nosebleeds',
    'Postnasal Drip',
    'Rhinorrhea',
    'Sinus Pain',
    'Sinus Pressure',
    'Sneezing',
    'Sore Throat',
    'Tinnitus',
    'Trouble Swallowing',
    'Voice Change',
  ],
  eyes: ['Eye Discharge', 'Eye Itching', 'Eye Pain', 'Eye Redness', 'Photophobia', 'Visual Disturbance'],
  cardio: ['Chest Pain', 'Leg Swelling', 'Palpitations'],
  respiratory: ['Apnea', 'Chest Tightness', 'Choking', 'Cough', 'Shortness of Breath', 'Stridor', 'Wheezing'],
  gi: [
    'Distension',
    'Abdominal Pain',
    'Anal Bleeding',
    'Blood in Stool',
    'Constipation',
    'Diarrhea',
    'Nausea',
    'Vomiting',
    'Rectal Pain',
  ],
  gu: [
    'Difficulty Urinating',
    'Dyspareunia',
    'Dysuria',
    'Enuresis',
    'Flank Pain',
    'Frequency',
    'Genital Sore',
    'Hematuria',
    'Menstrual Problem',
    'Pelvic Pain',
    'Urgency',
    'Urine Decreased',
    'Vaginal Bleeding',
    'Vaginal Discharge',
    'Vaginal Pain',
  ],
  muscle: ['Arthralgias', 'Back Pain', 'Gait Problem', 'Joint Swelling', 'Myalgias', 'Neck Pain', 'Neck Stiffness'],
  skin: ['Color Change', 'Pallor', 'Rash', 'Wound'],
  heme: ['Adenopathy', 'Easy Bleed/Bruise'],
  immuno: ['Env Allergies', 'Food Allergies', 'Immunocomp'],
  endocrine: ['Cold Intolerance', 'Heat Intolerance', 'Polydipsia', 'Polyphagia', 'Polyuria'],
  neuro: [
    'Dizziness',
    'Facial Asymmetry',
    'Headaches',
    'Lightheaded',
    'Numbness',
    'Seizures',
    'Speech Diff',
    'Syncope',
    'Tremors',
    'Weakness',
  ],
  psych: [
    'Agitation',
    'Behavior Problem',
    'Confusion',
    'Decr Concentration',
    'Dysphoric Mood',
    'Hallucinations',
    'Hyperactive',
    'Nervous/Anxious',
    'Self-Injury',
    'Sleep Disturbance',
    'Suicidal Ideas',
  ],
};

export const allStates: string[] = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const blankMedication: Medication = {
  medicationName: '',
};

export const StyledDiv = styled.div<{ $colorScheme: ColorScheme }>`
  .background-primary-100 {
    background-color: ${({ $colorScheme }) => $colorScheme.primaryColor100};
  }
  .background-primary-300 {
    background-color: ${({ $colorScheme }) => $colorScheme.primaryColor300};
  }
  .background-primary-500 {
    background-color: ${({ $colorScheme }) => $colorScheme.primaryColor500};
  }
  .background-primary-700 {
    background-color: ${({ $colorScheme }) => $colorScheme.primaryColor700};
  }
`;
