import { useState, useEffect } from 'react';
import { Note, Patient } from '../../../types';
import ProgressNoteHPI from './ProgressNoteHPI';
import ProgressNoteMedications from './ProgressNoteMedications';
import ProgressNoteAllergies from './ProgressNoteAllergies';
import ProgressNotePastMedicalHistories from './ProgressNotePastMedicalHistories';
import ProgressNotePastSurgicalHistories from './ProgressNotePastSurgicalHistories';
import ProgressNoteFamilyHistories from './ProgressNoteFamilyHistories';
import ProgressNoteSocialHistories from './ProgressNoteSocialHistories';
import ProgressNoteReviewOfSystems from './ProgressNoteReviewOfSystems';
import ProgressNoteVitalSigns from './ProgressNoteVitalSigns';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { blankNote, cleanPatientNotes, getPatientNotes, savePatientNote } from '../../../redux/slices/noteSlice';
import ProgressNotePhysicalExam from './ProgressNotePhysicalExam';
import ProgressNoteAssessmentPlans from './ProgressNoteAssessmentPlans';
import ProgressNoteFollowUp from './ProgressNoteFollowUp';
import ProgressNoteLevelOfService from './ProgressNoteLevelOfService';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import PrimaryButton from '../../custom/PrimaryButton';
import { format } from 'date-fns';
import { generateRandomColorFromString, getInitials, historyDateFormat } from '../../common/functions';
import { Avatar } from '@mui/material';

enum EncounterPage {
  HISTORY,
  NOTE,
}

export default function ProgressNoteContainer({
  patient,
  isPatient,
}: {
  patient: Patient | null;
  isPatient?: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const currentNote = useSelector((state: RootState) => state.note.currentNote);
  const [progressNote, setProgressNote] = useState<Note>(blankNote);
  const [page, setPage] = useState<EncounterPage>(EncounterPage.HISTORY);
  const problems = useSelector((state: RootState) => state.note.problems ?? []);
  const pageClass: string = 'underline font-bold';

  useEffect(() => {
    if (patient) dispatch(getPatientNotes(patient.patientId));
    return () => {
      dispatch(cleanPatientNotes());
    };
  }, []);

  useEffect(() => {
    setProgressNote(currentNote);
  }, [currentNote]);

  const changeProgressNote = (props: Partial<Note>) => {
    setProgressNote({
      ...progressNote,
      ...props,
    });
  };

  const pageData = () => {
    switch (page) {
      case EncounterPage.HISTORY:
        return (
          <>
            <div className="grid grid-cols-3  py-2">
              <h2 className="text-2xl font-bold">Problem History</h2>
            </div>
            <ul>
              {problems.map((problemHistory, index: number) => {
                return (
                  <li
                    className="border flex gap-2 p-2 border-gray-400 background-primary-100 rounded mt-2"
                    key={`problem-${index}`}
                  >
                    <div>
                      <Avatar
                        sx={{
                          bgcolor: generateRandomColorFromString(problemHistory.insertUser?.firstName),
                        }}
                      >
                        {getInitials(problemHistory.insertUser?.firstName, problemHistory.insertUser?.lastName)}
                      </Avatar>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between">
                        <div>
                          <span className="text-blue-500">{problemHistory.insertUser?.firstName}</span> |{' '}
                          <span>{problemHistory?.problem}</span>
                        </div>
                        {problemHistory.insertDate && (
                          <div>
                            {format(new Date(problemHistory.insertDate), 'MM/dd/yyyy')}{' '}
                            <span className="opacity-70">{historyDateFormat(new Date(problemHistory.insertDate))}</span>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col mt-2">
                        <p>Text instructions:</p>
                        <ul className="list-disc list-inside">
                          {problemHistory.assessmentTextInstructions?.map((text, index) => (
                            <li key={index}>{text}</li>
                          ))}
                        </ul>
                        <div className="mt-2">
                          <p>Prescribed medications:</p>
                          {problemHistory.assessmentMedications?.map((medic, index) => (
                            <span key={index} className="border px-2 border-gray-400 rounded ml-2">
                              <span className="font-medium">{medic.medicationName}</span>{' '}
                              {medic.sig && `: ${medic.sig}`}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        );
      case EncounterPage.NOTE:
        return (
          <>
            <div className="grid grid-cols-3 border-b py-2">
              <h2 className="text-2xl font-bold">Progress Note</h2>
            </div>
            <div style={{ overflowY: 'auto' }}>
              <ProgressNoteHPI progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteMedications progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteAllergies progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNotePastMedicalHistories progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNotePastSurgicalHistories progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteFamilyHistories progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteSocialHistories progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteReviewOfSystems progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteVitalSigns progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNotePhysicalExam progressNote={progressNote} changeProgressNote={changeProgressNote} />
              {/* <ProgressNoteLaboratoryResults progressNote={progressNote} changeProgressNote={changeProgressNote} />
                <ProgressNoteImaging progressNote={progressNote} changeProgressNote={changeProgressNote} /> */}
              <ProgressNoteAssessmentPlans
                progressNote={progressNote}
                changeProgressNote={changeProgressNote}
                patient={patient}
              />
              <ProgressNoteFollowUp progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <ProgressNoteLevelOfService progressNote={progressNote} changeProgressNote={changeProgressNote} />
              <div className="flex justify-end mt-2">
                <PrimaryButton
                  onClick={async () => {
                    if (!patient?.patientId || !progressNote.hpi || !progressNote.hpiShort) {
                      toast.error('Please fill HPI');
                      return;
                    }
                    progressNote.noteId = uuid();
                    progressNote.patientId = patient!.patientId;
                    dispatch(savePatientNote(progressNote));
                    setProgressNote(blankNote);
                    toast.success('Successfully saved the note');
                  }}
                >
                  Sign All
                </PrimaryButton>
              </div>
              <div className="p-25">
                <br />
                <br />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="w-full border-l-2 flex flex-col p-4 overflow-y-clip">
      <div className="flex justify-center gap-3">
        <div
          className={page === EncounterPage.HISTORY ? pageClass : 'cursor-pointer'}
          onClick={() => setPage(EncounterPage.HISTORY)}
        >
          Problem History
        </div>
        {!isPatient && (
          <>
            <div>&bull;</div>
            <div
              className={page === EncounterPage.NOTE ? pageClass : 'cursor-pointer'}
              onClick={() => setPage(EncounterPage.NOTE)}
            >
              Progress Note
            </div>
          </>
        )}
      </div>
      {pageData()}
    </div>
  );
}
