import styles from './header.module.css';
import filumHealthLogo from '../../assets/FilumLogo.png';
import scheduleIcon from '../../assets/providerDashboardHeader/Schedule.png';
import patientIcon from '../../assets/providerDashboardHeader/Patient.png';
import SearchBox from './SearchBox';
import ProfileHeader from './ProfileHeader';
import { Link } from 'react-router-dom';
import { getUser } from '../../api';

export default function Header() {
  return (
    <div className={`${styles.headerRoot} background-primary-500`}>
      <Link to={getUser()!.isPatient ? '/dashboard-patient' : '/dashboard'}>
        <img className={styles.filumHealthLogo} src={filumHealthLogo} alt="FilumHealth Logo" />
      </Link>
      {!getUser()!.isPatient && (
        <div className={styles.quickLinksAndSearch}>
          <div className={styles.quickLinks}>
            <Link to="/dashboard">
              <img className={styles.quickLinksIcon} src={scheduleIcon} alt="Schedule Icon" />
            </Link>
            <Link to={'/patients'}>
              <img className={styles.quickLinksIcon} src={patientIcon} alt="Patient Icon" />
            </Link>
          </div>
          <div style={{ height: '40px' }}>
            <SearchBox />
          </div>
        </div>
      )}
      <div>
        <ProfileHeader />
      </div>
    </div>
  );
}
