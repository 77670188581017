import { useLocation } from 'react-router';
import PatientInfoContainer from '../components/providerEncounter/patient-info/PatientInfoContainer';
import ProgressNoteContainer from '../components/providerEncounter/progress-note/ProgressNoteContainer';
import Header from '../components/topbar/Header';

export default function ProviderEncounterPage() {
  const { state } = useLocation();
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header />
      <div className="flex h-full w-full">
        <PatientInfoContainer patient={state?.patient} />
        <ProgressNoteContainer patient={state?.patient} />
      </div>
    </div>
  );
}
