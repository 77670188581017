import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Medication, Patient, Problem, ProgressNoteProps } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { blankMedication } from '../../common/Consts';
import { getUser } from '../../../api';
import { historyDateFormat } from '../../common/functions';
import { toast } from 'react-toastify';

interface ProgressNoteAssessmentPlansProps extends ProgressNoteProps {
  patient: Patient | null;
}

const ProgressNoteAssessmentPlans: React.FC<ProgressNoteAssessmentPlansProps> = ({
  progressNote,
  changeProgressNote,
  patient,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentMedication, setCurrentMedication] = useState<Medication>({
    ...blankMedication,
    insertDate: new Date().toISOString(),
  });
  const [textInstruction, setTextInstruction] = useState<string>('');
  const [problemName, setProblemName] = useState<string>('');

  function addMedicationToProblem() {
    if (!progressNote.problems) return;
    if (!currentMedication.medicationName) {
      toast.error('Medication name is required or there has to be a problem info entered.');
      return;
    }
    const newMedication = progressNote.problems[0].assessmentMedications ?? [];
    newMedication.push(currentMedication);
    const updatedProblem: Problem = {
      ...progressNote.problems[0],
      assessmentMedications: newMedication,
    };
    const newProblems = progressNote.problems.slice(1);
    changeProgressNote({
      problems: [updatedProblem, ...newProblems],
    });
    setCurrentMedication(blankMedication);
    setDialogOpen(false);
  }

  return (
    <ProgressNoteCollapse name="Assessment & Plan">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <TextField
                className="bg-white"
                label="Add Problem"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AddIcon
                        className="cursor-pointer"
                        onClick={() => {
                          const user = getUser();
                          if (!problemName || !user) return;
                          const prevState = progressNote.problems ?? [];
                          changeProgressNote({
                            problems: [
                              {
                                problem: problemName,
                                insertUser: user,
                                insertDate: new Date().toISOString(),
                              },
                              ...prevState,
                            ],
                          });
                          setProblemName('');
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={problemName}
                onChange={(e) => setProblemName(e.target.value)}
              />
            </div>
            {progressNote.problems?.map((problemHistory, index) => {
              return (
                <div
                  className={`${
                    index === 0 ? 'bg-white' : 'bg-gray-100'
                  } border flex flex-col gap-2 p-2 border-gray-400  rounded`}
                  key={`problem-${index}`}
                >
                  <div className="flex justify-between">
                    <div>
                      <span className="text-blue-500">{problemHistory?.insertUser?.firstName}</span> |{' '}
                      <span>{problemHistory?.problem}</span>
                    </div>
                    {problemHistory.insertDate && (
                      <div>
                        {format(new Date(problemHistory.insertDate), 'MM/dd/yyyy')}{' '}
                        <span className="opacity-70">{historyDateFormat(new Date(problemHistory.insertDate))}</span>
                      </div>
                    )}
                  </div>
                  {index === 0 && (
                    <div className="flex w-full">
                      <TextField
                        className="w-full"
                        placeholder="Enter Assessment"
                        value={textInstruction}
                        onChange={(e) => setTextInstruction(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="flex flex-col">
                    {problemHistory.assessmentTextInstructions?.map((text, index) => (
                      <div key={index}>
                        Text: <span className="border px-2 border-gray-400 rounded">{text}</span>
                      </div>
                    ))}
                    {problemHistory.assessmentMedications?.map((medic, index) => (
                      <div key={index}>
                        Medic: <span className="border px-2 border-gray-400 rounded">{medic.medicationName}</span>
                      </div>
                    ))}
                  </div>
                  {index === 0 && (
                    <div className="flex justify-end">
                      <div className="flex gap-3">
                        <PrimaryButton
                          onClick={() => {
                            if (!textInstruction) return;
                            const newText = problemHistory.assessmentTextInstructions ?? [];
                            newText.push(textInstruction);
                            const updatedProblem = {
                              ...problemHistory,
                              assessmentTextInstructions: newText,
                            };
                            const newProblems =
                              progressNote.problems?.filter((problem) => problem.problem !== problemHistory.problem) ??
                              [];
                            changeProgressNote({
                              problems: [updatedProblem, ...newProblems],
                            });
                            setTextInstruction('');
                          }}
                        >
                          Submit Free Text Instructions
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => {
                            setDialogOpen(true);
                          }}
                        >
                          New Order
                        </PrimaryButton>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <Dialog open={dialogOpen}>
          <DialogContent className="bg-primary-300">
            <DialogTitle>Add Medication</DialogTitle>
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-2 gap-5 bg-primary-100 p-2 h-full">
                <div className="flex flex-col gap-5">
                  <TextField
                    label="Medication name *"
                    variant="standard"
                    value={currentMedication.medicationName}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, medicationName: e.target.value })}
                  />
                  <TextField
                    label="Sig"
                    variant="standard"
                    value={currentMedication.sig}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, sig: e.target.value })}
                  />
                  <div className="grid grid-cols-4 gap-2">
                    <TextField
                      label="Qty"
                      variant="standard"
                      type="number"
                      value={currentMedication.qty}
                      onChange={(e) => setCurrentMedication({ ...currentMedication, qty: parseInt(e.target.value) })}
                    />
                    <FormControl variant="standard" className="col-span-2">
                      <InputLabel>Unit</InputLabel>
                      <Select
                        value={currentMedication.unit}
                        onChange={(e) => setCurrentMedication({ ...currentMedication, unit: e.target.value })}
                      >
                        <MenuItem value={'bottle'}>Bottle</MenuItem>
                        <MenuItem value={'tablets'}>Tablets</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Refills"
                      variant="standard"
                      type="number"
                      value={currentMedication.refills}
                      onChange={(e) =>
                        setCurrentMedication({ ...currentMedication, refills: parseInt(e.target.value) })
                      }
                    />
                  </div>
                  <TextField
                    label="Diagnosis (ICD-10)"
                    variant="standard"
                    value={currentMedication.diagnosis}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, diagnosis: e.target.value })}
                  />
                  <div>
                    <input
                      type="checkbox"
                      id="daw"
                      checked={currentMedication.daw}
                      onChange={() => {
                        setCurrentMedication({ ...currentMedication, daw: !currentMedication.daw });
                      }}
                    />
                    <label className="ml-2" htmlFor="daw">
                      Dispense as Written
                    </label>
                  </div>
                </div>

                <div className="flex flex-col gap-5">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Earliest Fill Date"
                      value={
                        currentMedication.earliestFillDate ? new Date(currentMedication.earliestFillDate) : null
                      }
                      onChange={(date: Date | null) => {
                        if (date != null) {
                          setCurrentMedication({ ...currentMedication, earliestFillDate: date.toISOString() });
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <TextField
                    label="Notes to Pharmacy"
                    variant="standard"
                    value={currentMedication.notesToPharmacy}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, notesToPharmacy: e.target.value })}
                  />
                  <TextField
                    label="Notes to Patient"
                    variant="standard"
                    value={currentMedication.notesToPatient}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, notesToPatient: e.target.value })}
                  />
                  <TextField
                    label="Allergic Reactions"
                    variant="standard"
                    value={currentMedication.allergicReactions}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, allergicReactions: e.target.value })}
                  />
                </div>
              </div>
              <div className="mt-2 flex justify-between">
                <div>
                  {patient?.pharmacy && (
                    <>
                      Pharmacy: <span className="underline text-blue-500">{patient.pharmacy}</span>
                    </>
                  )}
                </div>
                <div className="flex gap-2">
                  <PrimaryButton onClick={addMedicationToProblem}>
                    Add medication for "{progressNote.problems && progressNote.problems[0]?.problem}"
                  </PrimaryButton>
                  <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteAssessmentPlans;
