import React, { useEffect, useState } from 'react';
import { format, startOfMonth, addMonths, startOfWeek, addDays, isSameDay, endOfMonth, endOfWeek } from 'date-fns';
import styles from './styles/calendarWidget.module.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { changeUserDateState } from '../../redux/slices/calendarSlice';

const CalendarWidget: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userDateProps = useSelector((state: RootState) => state.calendar.userDate);
  const [currentDateBoxes, setCurrentDateBoxes] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setCurrentDateBoxes(createDateBoxes());
  }, [userDateProps]);

  const handleDateClick = (date: Date) => {
    dispatch(
      changeUserDateState({
        selectedDate: date.getTime(),
        displayedDate: date.getTime(),
      })
    );
  };

  const handlePrevMonthClick = () => {
    const updatedDate = addMonths(userDateProps.displayedDate, -1).getTime();
    dispatch(
      changeUserDateState({
        displayedDate: updatedDate,
      })
    );
  };

  const handleNextMonthClick = () => {
    const updatedDate = addMonths(userDateProps.displayedDate, 1).getTime();
    dispatch(
      changeUserDateState({
        displayedDate: updatedDate,
      })
    );
  };

  const handleTodayClick = () => {
    const todayDate: Date = new Date();
    const todayTimestamp = todayDate.getTime();
    dispatch(
      changeUserDateState({
        displayedDate: todayTimestamp,
        selectedDate: todayTimestamp,
      })
    );
  };

  const renderWeekdays = () => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays.map((weekday) => (
      <div className={styles.weekday} key={weekday}>
        {weekday}
      </div>
    ));
  };

  const setDateBoxColor = (buildDay: Date, displayedMonth: number, selectedDate?: number): string => {
    if (buildDay.getMonth() !== new Date(displayedMonth).getMonth()) {
      return 'lightgray';
    } else if (
      selectedDate &&
      buildDay.getDate() === new Date(selectedDate).getDate() &&
      buildDay.getMonth() === new Date(selectedDate).getMonth() &&
      buildDay.getFullYear() === new Date(selectedDate).getFullYear()
    ) {
      return '#9ADAE7';
    } else {
      return 'white';
    }
  };

  const createDateBoxes = () => {
    const monthStart = startOfMonth(userDateProps.displayedDate);
    const startCalMonth = startOfWeek(monthStart);
    const monthEnd = endOfMonth(userDateProps.displayedDate);
    const endCalMonth = endOfWeek(monthEnd);

    const days: JSX.Element[] = [];

    let buildDay = startCalMonth;

    while (buildDay <= endCalMonth) {
      for (let i = 0; i < 7; i++) {
        const currentDay = buildDay;
        days.push(
          <div
            className={styles.dateBox}
            key={buildDay.toString()}
            onClick={() => handleDateClick(currentDay)}
            style={{
              background: setDateBoxColor(buildDay, userDateProps.displayedDate, userDateProps.selectedDate),
              cursor: 'pointer',
            }}
          >
            {format(buildDay, 'd')}
          </div>
        );
        buildDay = addDays(buildDay, 1);
      }
    }

    return days;
  };

  return (
    <div className={styles.calendarWidget}>
      <div className={styles.monthYearHeader}>
        <div className={styles.prevButton} onClick={handlePrevMonthClick}>
          <ArrowLeftIcon style={{ width: '16px', height: '16px' }} className="cursor-pointer" />
        </div>
        <div className={styles.monthYearDisplay}>{format(userDateProps.displayedDate, 'MMMM yyyy')}</div>
        <div className={styles.nextButton} onClick={handleNextMonthClick}>
          <ArrowRightIcon style={{ width: '16px', height: '16px' }} className="cursor-pointer" />
        </div>
        <div
          className={`${
            isSameDay(userDateProps.selectedDate, Date.now()) ? styles.todayButton : styles.todayButtonOff
          } cursor-pointer`}
          onClick={handleTodayClick}
        >
          Today
        </div>
      </div>

      <div className={styles.calendarBody}>
        <div className={styles.weekdayRow}>{renderWeekdays()}</div>
        <div className={styles.dateRow}>{currentDateBoxes}</div>
      </div>
    </div>
  );
};

export default CalendarWidget;
