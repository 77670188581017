import ScheduleDatePicker from './ScheduleDatePicker';

const ScheduleHeader: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', background: '#D6F0FA' }}>
      <div style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '1em', padding: '.5em', width: '16.5em' }}>
        Schedule
      </div>
      <ScheduleDatePicker />
    </div>
  );
};

export default ScheduleHeader;
