import React from 'react';
import BasicInfoTextField from '../common/BasicInfoTextField';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ProviderSignupForm } from '../../../types';
import { allStates } from '../../common/Consts';
import { formatPhoneNumber } from '../../common/functions';
import PasswordPolicyValidator from '../../auth/PasswordPolicyValidator';

export type ProviderBasicInfoFormProps = {
  providerBasicInfo: ProviderSignupForm;
  setBasicInfoCallback: (providerBasicInfo: ProviderSignupForm) => void;
};

const ProviderBasicInfoForm: React.FC<ProviderBasicInfoFormProps> = ({ providerBasicInfo, setBasicInfoCallback }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '.5em' }}>
      <BasicInfoTextField
        fieldLabel="First Name *"
        fieldValue={providerBasicInfo.firstName}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            firstName: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Last Name *"
        fieldValue={providerBasicInfo.lastName}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            lastName: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Email Address *"
        fieldValue={providerBasicInfo.email}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            email: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Phone Number *"
        fieldValue={formatPhoneNumber(providerBasicInfo.phoneNumber)}
        onChangeCallback={(e) => {
          const formattedPhoneNumber = formatPhoneNumber(e.target.value);
          const phoneNumberWithoutFormat = formattedPhoneNumber
            .replace('-', '')
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '');
          setBasicInfoCallback({
            ...providerBasicInfo,
            phoneNumber: phoneNumberWithoutFormat,
          });
        }}
      />
      <BasicInfoTextField
        fieldLabel="NPI *"
        fieldValue={providerBasicInfo.npi || ''}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            npi: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Role"
        fieldValue={providerBasicInfo.role || ''}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            role: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Fax Number"
        fieldValue={providerBasicInfo.fax || ''}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            fax: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Address 1 *"
        fieldValue={providerBasicInfo.address1}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            address1: e.target.value,
          })
        }
      />
      <BasicInfoTextField
        fieldLabel="Address 2"
        fieldValue={providerBasicInfo.address2}
        onChangeCallback={(e) =>
          setBasicInfoCallback({
            ...providerBasicInfo,
            address2: e.target.value,
          })
        }
      />
      <div className="grid grid-cols-3 gap-2">
        <div>
          <BasicInfoTextField
            fieldLabel="City *"
            fieldValue={providerBasicInfo.city}
            onChangeCallback={(e) =>
              setBasicInfoCallback({
                ...providerBasicInfo,
                city: e.target.value,
              })
            }
          />
        </div>
        <div className="mt-2">
          <FormControl fullWidth>
            <InputLabel>State *</InputLabel>
            <Select
              name="state"
              value={providerBasicInfo.state}
              onChange={(e) =>
                setBasicInfoCallback({
                  ...providerBasicInfo,
                  state: e.target.value,
                })
              }
            >
              {allStates.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <BasicInfoTextField
            fieldLabel="Zip Code *"
            fieldValue={providerBasicInfo.zipCode}
            onChangeCallback={(e) => {
              if (e.target.value.length < 6 && (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value))) {
                setBasicInfoCallback({
                  ...providerBasicInfo,
                  zipCode: e.target.value,
                });
              }
            }}
          />
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <TextField
          fullWidth
          type="password"
          label="Password *"
          value={providerBasicInfo.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBasicInfoCallback({
              ...providerBasicInfo,
              password: e.target.value,
            })
          }
          sx={{ fontSize: '1em' }}
        />
        <PasswordPolicyValidator password={providerBasicInfo.password} />
      </div>
    </div>
  );
};

export default ProviderBasicInfoForm;
