import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface PrimaryButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}
const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, children, className }) => {
  const { colorScheme } = useSelector((state: RootState) => state.settings);
  return (
    <button
      className={`hover:opacity-70 text-white font-semibold px-3 rounded inline-block ${className}`}
      onClick={onClick}
      style={{
        backgroundColor: colorScheme.primaryColor700,
      }}
    >
      {children}
    </button>
  );
};
export default PrimaryButton;
