import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { confirmProviderVerification, login } from '../../../api';
import { toast } from 'react-toastify';
import Loader from '../../common/Loadert';

export default function ProviderVerification({ emailProp, password }: { emailProp: string; password: string }) {
  const [email, setEmail] = useState(emailProp || '');
  const [code, setCode] = useState('');
  const [registrationCode, setRegistrationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();

  async function checkVerification() {
    if (!email || !code || !registrationCode) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    setError(null);
    setLoading(true);
    if (await confirmProviderVerification(email, code, registrationCode)) {
      if (password) {
        await login(email, password, 'provider');
        navigate('/dashboard');
      } else {
        toast.success('Verification code is correct. Please login.');
        navigate('/login/provider');
      }
    } else {
      setError('Verification code is invalid.');
      toast.error(error);
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h2>
        <center className="my-5">Registration Verification</center>
      </h2>
      <div>
        <TextField
          disabled
          fullWidth
          label="email"
          sx={{ fontSize: '1em' }}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
      </div>
      <div style={{ paddingTop: '.5em' }}>
        <TextField
          fullWidth
          label="Verification code in email *"
          value={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
          sx={{ fontSize: '1em' }}
        />
      </div>
      <div style={{ paddingTop: '.5em' }}>
        <TextField
          fullWidth
          label="Registration code privately sent to you *"
          value={registrationCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRegistrationCode(e.target.value)}
          sx={{ fontSize: '1em' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '.5em',
        }}
      >
        <div style={{ flex: 1 }}>
          <Button
            variant="contained"
            disableElevation={true}
            fullWidth
            sx={{
              fontSize: '.8em',
              background: '#9ADAE7',
              '&:hover': {
                backgroundColor: '#5C9CB8',
              },
              borderRadius: '4rem',
            }}
            disabled={loading}
            onClick={checkVerification}
          >
            <div style={{ color: 'black' }}>Submit</div>
          </Button>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}
