/* eslint-disable max-len */
import GuestTopBar from '../components/common/GuestTopBar';
import useWindowResize from '../hooks/useWindowResize';
import AboutUsImage from '../assets/AboutUs.png';
import { Link } from '@mui/material';
import Copyright from '../components/common/Copyright';

const AboutUsPage: React.FC = () => {
  const { windowWidth } = useWindowResize();
  const isSmallScreen = windowWidth < 800;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        paddingRight: '5em',
        paddingLeft: '5em',
        background: 'linear-gradient(to bottom, white, #D6F0FA)',
      }}
    >
      <div>
        <GuestTopBar selectedOption='about' />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          paddingLeft: '1em',
          paddingRight: '1em',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '75%',
          }}
        >
          <div>
            <div style={{ fontSize: '32px', fontWeight: 'bold' }}>Welcome to FilumHealth</div>
            <div>
              <Link
                color="#507D95"
                href="mailto: investments@filumhealth.com"
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                Investment Opprotunities
              </Link>
              {' | '}
              <Link
                color="#507D95"
                href="mailto: partners@filumhealth.com"
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                Partner With FilumHealth
              </Link>
              {' | '}
              <Link
                color="#507D95"
                href="mailto: info@filumhealth.com"
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                Contact Us
              </Link>
            </div>
          </div>

          <div style={{ fontSize: '24px', fontWeight: 'bold', paddingTop: '1em' }}>Mission Statement</div>
          <p>
            Our mission at FilumHealth is to empower individuals by enabling them to access top quality healthcare from
            anywhere in the world, while ensuring their safety and security. We aim to achieve this by providing
            innovative software solutions that enhance the quality of patient care and facilitate seamless communication
            between healthcare providers and patients. At FilumHealth, we are committed to improving patient outcomes
            and delivering a world-class healthcare experience to all.
          </p>
        </div>
        {isSmallScreen ? (
          <></>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '2em',
            }}
          >
            <img
              src={AboutUsImage}
              alt="About FilumHealth"
              style={{ height: '50vh', maxHeight: '400px', objectFit: 'cover' }}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '1em',
          paddingRight: '1em',
        }}
      >
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Our Vision:</div>
        <ul>
          <li style={{ marginBottom: '1em' }}>
            {
              'To revolutionize healthcare by putting patients at the center of their care, and providing them with true ownership of their health records.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To empower healthcare providers by optimizing their workflow and enabling them to focus on delivering high-quality care to their patients.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To improve the quality of care and reduce errors during transitions of care by facilitating seamless data transmission between healthcare providers and settings.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To support the growth and proliferation of small practices, and promote a diverse and inclusive healthcare system.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To increase patient choice and awareness around their health and health records, and empower them to make informed decisions about their care.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {'To enhance patient-healthcare provider relationships by promoting open communication and collaboration.'}
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To grow and strengthen healthcare provider professional networks, and foster a culture of continuous learning and improvement.'
            }
          </li>
          <li style={{ marginBottom: '1em' }}>
            {
              'To lead the way in developing innovative healthcare technologies that improve patient outcomes and advance the healthcare industry as a whole.'
            }
          </li>
        </ul>
      </div>
      <div style={{ marginTop: '1em' }}>
        <Copyright />
      </div>
    </div>
  );
};

export default AboutUsPage;
