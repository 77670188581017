import { Link, Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
      <Link color="inherit" href="https://FilumHealth.com/">
        FilumHealth
      </Link>{' '}
      {new Date().getFullYear()}
      {' | '}
      <Link color="inherit" href="mailto: info@filumhealth.com">
        Contact Us
      </Link>
    </Typography>
  );
};
