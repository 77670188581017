import { ProgressNoteProps } from '../../../types';
import ProgressNoteCollapse from './ProgressNoteCollapse';

const ProgressNoteHPI: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  return (
    <ProgressNoteCollapse name="HPI" openByDefault={true}>
      <>
        <div className="flex flex-col bg-gray-100 p-4 gap-2">
          <label>Chief Complaint</label>
          <input
            value={progressNote.hpiShort}
            onChange={(e) =>
              changeProgressNote({
                hpiShort: e.target.value,
              })
            }
            className="rounded px-4 py-1 border shadow outline-none"
            type="text"
            placeholder="Enter Complaint"
          />
          <label>HPI</label>
          <textarea
            value={progressNote.hpi}
            onChange={(e) =>
              changeProgressNote({
                hpi: e.target.value,
              })
            }
            className="rounded px-4 py-1 border shadow outline-none"
            placeholder="Enter HPI"
            rows={4}
          />
        </div>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteHPI;
