import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Note, Problem } from '../../types';
import { getNotesByPatientId, getUser, storeNote } from '../../api';

export interface NoteState {
  prevNotes: Note[];
  currentNote: Note;
  medicationsSummary: string[];
  allergiesSummary: string[];
  pastMedicalHistoriesSummary: string[];
  pastSurgicalHistoriesSummary: string[];
  familyHistorySummary: string[];
  socialHistorySummary: string[];
  problems: Problem[];
  loading: boolean;
  errorMessage: string;
}

export const getPatientNotes = createAsyncThunk('note/fetchNotesByPatientId', async (patientId: string) => {
  return await getNotesByPatientId(patientId);
});

export const savePatientNote = createAsyncThunk('note/savePatientNote', async (note: Note) => {
  await storeNote(note);
  return note;
});

export const blankNote: Note = {
  noteId: '',
  hpiShort: '',
  hpi: '',
  insertUser: getUser()!,
};

const initialState: NoteState = {
  prevNotes: [],
  currentNote: blankNote,
  loading: false,
  errorMessage: '',
  medicationsSummary: [],
  allergiesSummary: [],
  pastMedicalHistoriesSummary: [],
  pastSurgicalHistoriesSummary: [],
  familyHistorySummary: [],
  socialHistorySummary: [],
  problems: [],
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    cleanPatientNotes: (state) => {
      state.currentNote = blankNote;
      state.prevNotes = [];
      state.medicationsSummary = [];
      state.allergiesSummary = [];
      state.pastMedicalHistoriesSummary = [];
      state.pastSurgicalHistoriesSummary = [];
      state.familyHistorySummary = [];
      state.socialHistorySummary = [];
      state.problems = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(savePatientNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(savePatientNote.fulfilled, (state, action) => {
      const newNote = action.payload as Note;
      state.currentNote = prepopulateNextNoteHistoricalData({ ...initialState.currentNote }, newNote, state);
      state.prevNotes = [newNote, ...state.prevNotes];
      if (newNote.problems && newNote.problems.length > 0) state.problems = [...newNote.problems, ...state.problems];
      state.loading = false;
    });
    builder.addCase(getPatientNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPatientNotes.fulfilled, (state, action) => {
      // clear note preventing duplicated data
      state.prevNotes = [];
      state.medicationsSummary = [];
      state.allergiesSummary = [];
      state.pastMedicalHistoriesSummary = [];
      state.pastSurgicalHistoriesSummary = [];
      state.familyHistorySummary = [];
      state.socialHistorySummary = [];
      state.problems = [];

      const prevNotes = (action.payload as Note[]).sort(
        (a, b) => new Date(b.insertDate!).getTime() - new Date(a.insertDate!).getTime()
      );

      if (prevNotes.length > 0) {
        // The latest note will always have accumulated patient's historical data.
        state.currentNote = prepopulateNextNoteHistoricalData({ ...initialState.currentNote }, prevNotes[0], state);
        state.prevNotes = prevNotes;
        prevNotes.forEach((note) => {
          if (note.problems && note.problems.length > 0) state.problems = [...state.problems, ...note.problems];
        });
      }

      state.loading = false;
    });
  },
});

function prepopulateNextNoteHistoricalData(nextNote: Note, latestNote: Note, state: any) {
  nextNote.medications = latestNote.medications;
  nextNote.allergies = latestNote.allergies;
  nextNote.pastMedicalHistories = latestNote.pastMedicalHistories;
  nextNote.pastSurgicalHistories = latestNote.pastSurgicalHistories;
  nextNote.familyHistory = latestNote.familyHistory;
  nextNote.socialHistory = latestNote.socialHistory;

  state.medicationsSummary = latestNote.medications?.map((item) => item.medicationName);
  state.allergiesSummary = latestNote.allergies?.map((item) => item.allergy);
  state.pastMedicalHistoriesSummary = latestNote.pastMedicalHistories?.map((item) => item.icdValue);
  state.pastSurgicalHistoriesSummary = latestNote.pastSurgicalHistories?.map((item) => item.surgery);
  state.familyHistorySummary = latestNote.familyHistory?.map((item) => item.familyHistory);
  state.socialHistorySummary = latestNote.socialHistory?.map((item) => item.socialHistory);

  return nextNote;
}

export const { cleanPatientNotes } = noteSlice.actions;
export default noteSlice.reducer;
