import { differenceInDays } from 'date-fns';

export const formatPhoneNumber = (phoneNumber?: string): string => {
  if (!phoneNumber) return '';
  let formattedNumber = phoneNumber.replace(/\D/g, '');

  if (formattedNumber.length < 4) {
    return formattedNumber;
  } else if (formattedNumber.length < 7) {
    return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3)}`;
  } else if (formattedNumber.length < 11) {
    return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
  } else {
    return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6, 10)}`;
  }
};

export const passwordValidator = (password: string) => {
  const lengthRegex = /.{8,}/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  const isLengthValid = lengthRegex.test(password);
  const hasUppercase = uppercaseRegex.test(password);
  const hasLowercase = lowercaseRegex.test(password);
  const hasSpecialChar = specialCharRegex.test(password);

  return {
    isLengthValid,
    hasUppercase,
    hasLowercase,
    hasSpecialChar,
  };
};

export const generateRandomColorFromString = (inputString: string): string => {
  if (!inputString) return '#6b8fc9';
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return `#${'00000'.substring(0, 6 - c.length)}${c}`;
};

export const calculateAgebyBirthdate = (birthdate: string) => {
  const now = new Date();
  const birth = new Date(birthdate);

  let age = now.getFullYear() - birth.getFullYear();
  const monthDiff = now.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birth.getDate())) {
    age--;
  }

  return age > 0 ? age : 0;
};

export function formatDateUnixDate(unixDate: number) {
  const date = new Date(parseInt(unixDate + ''));
  return date.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    hour: '2-digit',
    minute: '2-digit',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
}

export function historyDateFormat(date: Date): string {
  const diffDays = differenceInDays(new Date(), date);
  switch (diffDays) {
    case 0:
      return 'Today';
    default:
      return `${diffDays} days ago`;
  }
}

export const celsiusToFahrenheit = (c: number) => {
  return parseFloat(((c * 9) / 5 + 32).toFixed(1));
};

export const fahrenheitToCelsius = (f: number) => {
  return parseFloat((((f - 32) * 5) / 9).toFixed(1));
};

export const cmToInch = (cm: number) => {
  return parseFloat((cm / 2.54).toFixed(1));
};

export const inchToCm = (inch: number) => {
  return parseFloat((inch * 2.54).toFixed());
};

export const kgToLbs = (kg: number) => {
  return parseFloat((kg * 2.20462).toFixed(2));
};


export const lbsToKg = (lbs: number) => {
  return parseFloat((lbs / 2.20462).toFixed(2));
};

export function getInitials(firstName: string, lastName: string) {
  if (!firstName || !lastName) return '00';
  return firstName.split('')[0].toUpperCase() + lastName.split('')[0].toUpperCase();
}
