import { useEffect, useState } from 'react';
import { addDays, addWeeks, format, isSameDay, startOfWeek } from 'date-fns';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { changeUserDateState } from '../../redux/slices/calendarSlice';

const ScheduleDatePicker: React.FC = () => {
  const [currentDateBoxes, setCurrentDateBoxes] = useState<JSX.Element[]>([]);
  const userDateProps = useSelector((state: RootState) => state.calendar.userDate);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setCurrentDateBoxes(buildDisplayedDates());
  }, [userDateProps]);

  const buildDisplayedDates = () => {
    const scheduleHeaderDays: JSX.Element[] = [];
    let buildScheduleDay = startOfWeek(userDateProps.displayedDate);

    for (let i = 0; i < 7; i++) {
      const currentDay = buildScheduleDay;
      scheduleHeaderDays.push(
        <div
          className="cursor-pointer"
          key={i}
          onClick={() => handleDateClick(currentDay)}
          style={{
            fontWeight: isSameDay(currentDay, userDateProps.selectedDate) ? 'bold' : 'normal',
          }}
        >
          {format(buildScheduleDay, 'EEE M/d')}
        </div>
      );
      buildScheduleDay = addDays(buildScheduleDay, 1);
    }

    return scheduleHeaderDays;
  };

  const handleDateClick = (date: Date) => {
    dispatch(
      changeUserDateState({
        displayedDate: date.getTime(),
        selectedDate: date.getTime(),
      })
    );
  };

  const handlePreviousWeekClick = () => {
    const updatedDate = addWeeks(userDateProps.displayedDate, -1).getTime();
    dispatch(changeUserDateState({ displayedDate: updatedDate }));
  };

  const handleNextWeekClick = () => {
    const updatedDate = addWeeks(userDateProps.displayedDate, 1).getTime();
    dispatch(changeUserDateState({ displayedDate: updatedDate }));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        padding: '.5em',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div onClick={handlePreviousWeekClick}>
        <ArrowLeftIcon
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
          }}
          className="cursor-pointer"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {currentDateBoxes}
      </div>
      <div onClick={handleNextWeekClick}>
        <ArrowRightIcon
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
          }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default ScheduleDatePicker;
