import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/topbar/Header';
import { SketchPicker } from 'react-color';
import { RootState } from '../redux/store';
import { ColorScheme, changeColorSchemeState, initialSettingState } from '../redux/slices/settingsSlice';
import { useState } from 'react';
import { Divider } from '@mui/material';
import PrimaryButton from '../components/custom/PrimaryButton';
const Settings: React.FC = () => {
  const { colorScheme } = useSelector((state: RootState) => state.settings);
  const [currentColors, setCurrentColors] = useState<ColorScheme>({
    ...colorScheme,
  });
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex flex-col mx-auto mt-10 w-1/2 gap-5">
        <Divider />
        <h1 className="text-2xl font-bold">Color scheme</h1>
        <div className="flex border px-4 py-2 justify-around">
          <ColorPicker
            color={currentColors.primaryColor100}
            name="Background 1"
            changeColor={(newColor: string) =>
              setCurrentColors({
                ...currentColors,
                primaryColor100: newColor,
              })
            }
          />
          <ColorPicker
            color={currentColors.primaryColor300}
            name="Background 2"
            changeColor={(newColor: string) =>
              setCurrentColors({
                ...currentColors,
                primaryColor300: newColor,
              })
            }
          />
          <ColorPicker
            color={currentColors.primaryColor500}
            name="Background 3"
            changeColor={(newColor: string) =>
              setCurrentColors({
                ...currentColors,
                primaryColor500: newColor,
              })
            }
          />
          <ColorPicker
            color={currentColors.primaryColor700}
            name="Button"
            changeColor={(newColor: string) =>
              setCurrentColors({
                ...currentColors,
                primaryColor700: newColor,
              })
            }
          />
        </div>
        <div className="flex justify-end gap-3">
          <PrimaryButton
            onClick={() => {
              setCurrentColors(initialSettingState.colorScheme);
              dispatch(changeColorSchemeState(initialSettingState.colorScheme));
            }}
          >
            Set Default
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              dispatch(changeColorSchemeState(currentColors));
            }}
          >
            Save settings
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

interface ColorPickerProps {
  color: string;
  changeColor: (newColor: string) => void;
  name: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, changeColor, name }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="flex flex-col ">
      <label className="font-semibold">{name}</label>
      <div className="flex gap-3 items-center">
        <div
          className="border border-gray-400 cursor-pointer"
          style={{
            backgroundColor: color,
            width: 30,
            height: 30,
          }}
          onClick={() => setOpen(!open)}
        ></div>
        <div className="font-medium">{color}</div>
      </div>
      {open && (
        <SketchPicker
          color={color}
          onChange={(color) => {
            changeColor(color.hex);
          }}
        />
      )}
    </div>
  );
};

export default Settings;
