import { format } from 'date-fns';
import Woman from '@mui/icons-material/Face2';
import Man from '@mui/icons-material/Face';
import { calculateAgebyBirthdate, formatPhoneNumber } from '../../common/functions';
import { useNavigate } from 'react-router-dom';
import { Appointment, AppointmentStatus } from '../../../types';

export type AppointmentCardProps = {
  timeSlot: Date;
  appointment: Appointment | null;
  schedulePatient: (startDate: Date) => void;
  updateAppointment: (appointment: Appointment) => void;
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  timeSlot,
  appointment,
  schedulePatient,
  updateAppointment,
}) => {
  const slotIndicatorColor = () => {
    switch (appointment?.appointmentStatus) {
      case AppointmentStatus.Scheduled:
        return 'green';
      case AppointmentStatus.Cancelled:
        return 'red';
      case AppointmentStatus.CheckedIn:
        return 'yellow';
      case AppointmentStatus.CheckedOut:
        return 'yellow';
      case AppointmentStatus.ReadyForDoctor:
        return 'yellow';
      case AppointmentStatus.Held:
        return 'red';
    }
  };
  const navigate = useNavigate();

  const slotIndicator = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '5px',
        height: '85%',
        background: slotIndicatorColor(),
      }}
    />
  );

  let patientIcon = undefined;
  let firstLastName = undefined;
  let patientInfo = undefined;
  let patientInsurance = undefined;
  let reason = undefined;
  let statusColor = 'black';
  if (appointment?.patient !== undefined && appointment?.appointmentStatus !== AppointmentStatus.Held) {
    patientIcon = appointment.patient.gender === 'Male' ? <Man /> : <Woman />;
    firstLastName = `${appointment.patient.firstName} ${appointment.patient.lastName}`;
    patientInfo = `${format(new Date(appointment.patient.dateOfBirth), 'MM/dd/yyyy')} (${calculateAgebyBirthdate(
      appointment.patient.dateOfBirth
    )}) | ${appointment.patient.gender} | ${formatPhoneNumber(appointment.patient.mobilePhone)}`;
    patientInsurance = appointment.patient.insuranceProvider;
    reason = appointment.reason;
    if (appointment.appointmentStatus === AppointmentStatus.ReadyForDoctor) {
      statusColor = '#D97706';
    } else if (appointment.appointmentStatus === AppointmentStatus.CheckedIn) {
      statusColor = '#047857';
    }
  } else {
    switch (appointment?.appointmentStatus) {
      case AppointmentStatus.Held:
        patientIcon = 'Held';
        break;
      default:
        patientIcon = 'Open';
        break;
    }
  }

  const actionButton = (patientIcon: any) => {
    switch (patientIcon) {
      case 'Open':
        return (
          <button
            className="border px-2 rounded background-primary-500 border-gray-400"
            onClick={() => schedulePatient(timeSlot)}
          >
            Open
          </button>
        );
      case 'Held':
        return <div className="border px-2 rounded bg-red-400 border-gray-400">Held</div>;
      default:
        return patientIcon;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: '4em',
        justifyContent: 'flex-start',
        gap: '2em',
        alignItems: 'center',
        marginTop: '5px',
        marginBottom: '5px',
        borderBottom: '1px solid',
      }}
    >
      {slotIndicator}
      <div style={{ fontWeight: 'bold', width: '5em' }}>{format(timeSlot, 'hh:mm a')}</div>
      <div style={{ width: '10em', color: statusColor, textAlign: 'center' }}>
        {appointment?.appointmentStatus && appointment?.appointmentStatus !== AppointmentStatus.Held && (
          <div>
            <select
              id="status"
              className="border rounded background-primary-300 p-1"
              value={appointment?.appointmentStatus}
              onChange={async (e) => {
                if (appointment) {
                  updateAppointment({
                    ...appointment,
                    appointmentStatus: e.target.value as AppointmentStatus,
                  });
                }
              }}
            >
              <option value={AppointmentStatus.Scheduled}>Scheduled</option>
              <option value={AppointmentStatus.ReadyForDoctor}>Ready for Doctor</option>
              <option value={AppointmentStatus.CheckedIn}>Checked In</option>
              <option value={AppointmentStatus.CheckedOut}>Checked Out</option>
              <option value={AppointmentStatus.Cancelled}>Cancelled</option>
            </select>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', width: '3em', justifyContent: 'center' }}>{actionButton(patientIcon)}</div>
      <div
        className="cursor-pointer"
        onClick={() => {
          if (appointment?.patient) {
            navigate('/encounter', {
              state: {
                patient: appointment?.patient,
              },
            });
          }
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '20em' }}>
          <div style={{ fontWeight: 'bold' }}>{firstLastName ?? ''}</div>
          <div style={{ fontSize: '.75em' }}>{patientInfo ?? ''}</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '15em' }}>
        <div style={{ fontWeight: 'bold' }}>{reason ?? ''}</div>
        <div style={{ fontSize: '.75em' }}>{patientInsurance ?? ''}</div>
      </div>
    </div>
  );
};

export default AppointmentCard;
