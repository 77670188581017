import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { ProgressNoteProps, SocialHistory } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { generateRandomColorFromString, getInitials } from '../../common/functions';

const ProgressNoteSocialHistories: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const [currentSocialHistory, setCurrentSocialHistory] = useState<SocialHistory>({
    socialHistory: '',
    enteredBy: {
      ...progressNote.insertUser,
    },
    insertDate: new Date().toISOString(),
  });

  const resetCurrentSocialHistory = () => {
    setCurrentSocialHistory({
      socialHistory: '',
      enteredBy: {
        ...progressNote.insertUser,
      },
      insertDate: new Date().toISOString(),
    });
  };

  const addSocialHistory = () => {
    if (!currentSocialHistory.socialHistory) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    const prevState = progressNote.socialHistory ?? [];
    changeProgressNote({
      socialHistory: [currentSocialHistory, ...prevState],
    });
    setDialogOpen(false);
    resetCurrentSocialHistory();
  };

  return (
    <ProgressNoteCollapse name="Social History">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.socialHistory && progressNote.socialHistory.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border rounded border-gray-400">
              {progressNote.socialHistory?.map((socialHistory, index) => {
                if (showAll || index === 0)
                  return (
                    <li key={index} className="w-full p-2 flex gap-5">
                      <div>
                        <Avatar
                          sx={{
                            width: 96,
                            height: 96,
                            bgcolor: generateRandomColorFromString(socialHistory.enteredBy?.firstName),
                          }}
                          alt={socialHistory.enteredBy?.firstName || ''}
                        >
                          {getInitials(socialHistory.enteredBy?.firstName, socialHistory.enteredBy?.lastName)}
                        </Avatar>
                      </div>
                      <div className="flex flex-col w-full gap-5">
                        <div className="flex justify-between">
                          <p className="font-semibold">{`${socialHistory.enteredBy?.firstName} 
                        ${socialHistory.enteredBy?.lastName}`}</p>
                          <div>
                            {format(new Date(socialHistory?.insertDate || new Date().toISOString()), 'MM/dd/yyyy')}
                          </div>
                        </div>
                        <p>{socialHistory.socialHistory}</p>
                      </div>
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <div className="flex justify-end gap-5">
            {!showAll && progressNote.socialHistory && progressNote.socialHistory.length > 1 && (
              <PrimaryButton
                onClick={() => {
                  setShowAll(true);
                }}
                className="pl-2 pr-0"
              >
                <div className="flex">
                  <div>{`See All (${progressNote.socialHistory?.length})`}</div>
                  <KeyboardArrowDownIcon />
                </div>
              </PrimaryButton>
            )}

            <PrimaryButton
              onClick={() => {
                resetCurrentSocialHistory();
                setDialogOpen(true);
              }}
            >
              Add +
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen}>
          <div
            className="bg-primary-300"
            style={{
              width: '600px',
            }}
          >
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>{'Add Social History'}</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col gap-5">
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Social History *</label>
                  <textarea
                    className="bg-white border rounded p-2"
                    rows={4}
                    value={currentSocialHistory.socialHistory}
                    placeholder="Enter social history.."
                    onChange={(e) =>
                      setCurrentSocialHistory({ ...currentSocialHistory, socialHistory: e.target.value })
                    }
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={addSocialHistory}>{'Add'}</PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteSocialHistories;
