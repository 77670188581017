import { Appointment, AppointmentStatus } from '../../types';
import { formatDateUnixDate } from '../common/functions';
import CalendarWidget from './CalendarWidget';
import { useState, useEffect, ReactNode } from 'react';

export default function ScheduleLeftPane({ appointments }: { appointments: Appointment[] }) {
  const [upcomingAppointments, setUpcomingAppointments] = useState<ReactNode[]>([]);
  useEffect(() => {
    const topAppointments = [];
    let cur = 0;
    for (let i = 0; i < appointments.length; i++) {
      if (
        appointments[i].appointmentStatus === AppointmentStatus.Scheduled &&
        appointments[i].startDate > new Date().getTime()
      ) {
        topAppointments.unshift(
          <li key={i}>{`${formatDateUnixDate(appointments[i].startDate)} - ${appointments[i].patient?.firstName}`}</li>
        );
        cur++;
        if (cur === 5) break;
      }
    }
    setUpcomingAppointments(topAppointments);
  }, [appointments]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '.5em',
        width: '14em',
        height: '100vh',
        background: '#D6F0FA',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CalendarWidget />
      </div>
      <div style={{ marginBottom: '.5em' }}>
        <hr />
      </div>
      <div className="font-bold">My Schedule</div>
      {upcomingAppointments.length > 0 ? <ul>{upcomingAppointments}</ul> : <p>No upcoming appointments</p>}
    </div>
  );
}
