import { passwordValidator } from '../common/functions';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export default function PasswordPolicyValidator({ password }: { password: string }) {
  const { isLengthValid, hasUppercase, hasLowercase, hasSpecialChar } = passwordValidator(password);
  return (
    <ul>
      <li className={`text-sm ${isLengthValid ? 'text-green-500' : 'text-red-500'}`}>
        {isLengthValid ? <CheckIcon /> : <ClearIcon />}At least 8 characters
      </li>
      <li className={`text-sm ${hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
        {hasUppercase ? <CheckIcon /> : <ClearIcon />}At least 1 uppercase letter
      </li>
      <li className={`text-sm ${hasLowercase ? 'text-green-500' : 'text-red-500'}`}>
        {hasLowercase ? <CheckIcon /> : <ClearIcon />}At least 1 lowercase letter
      </li>
      <li className={`text-sm ${hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
        {hasSpecialChar ? <CheckIcon /> : <ClearIcon />}At least 1 special character
      </li>
    </ul>
  );
}
