import { Navigate } from 'react-router-dom';
import { getUser } from '../../api';

export function ProtectedRoute(props: any): React.ReactElement {
  const { children } = props;
  return getUser() ? children : <Navigate to={'/about-us'} replace />;
}

export function ProviderRouteRoute(props: any): React.ReactElement {
  const { children } = props;
  const user = getUser();
  if (!user) return <Navigate to={'/about-us'} replace />;
  if (user.isPatient) return <Navigate to={'/dashboard-patient'} replace />;
  return children;
}

export function PatientRouteRoute(props: any): React.ReactElement {
  const { children } = props;
  const user = getUser();
  if (!user) return <Navigate to={'/about-us'} replace />;
  if (!user.isPatient) return <Navigate to={'/dashboard'} replace />;
  return children;
}
