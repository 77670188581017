import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getUser, changeTempPassword } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../common/Loadert';
import PasswordPolicyValidator from './PasswordPolicyValidator';

export default function LoginForm({ whichLogin }: { whichLogin: string }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) return;
    setLoading(true);
    if (showNewPassword) {
      if (!newPassword) return;
      if (!(await changeTempPassword(username, password, newPassword))) {
        toast.error('Sorry, something went wrong');
        setLoading(false);
        return;
      }
    }

    const res = await login(username, showNewPassword ? newPassword : password, whichLogin);
    setLoading(false);

    if (res === 'NEW_PASSWORD_REQUIRED') {
      setShowNewPassword(true);
      return;
    }

    const loggedInUser = getUser();
    if (loggedInUser) {
      if (whichLogin === 'patient') {
        navigate('/dashboard-patient');
      } else {
        navigate('/dashboard');
      }
    } else {
      toast.error('Invalid credentials');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h2>
        <center>{whichLogin === 'patient' ? 'Patient Login' : 'Provider Login'}</center>
      </h2>
      <div>
        <TextField
          fullWidth
          label="Email"
          sx={{ fontSize: '1em' }}
          value={username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
        />
      </div>
      <div style={{ paddingTop: '.5em' }}>
        <TextField
          fullWidth
          type={showPassword ? undefined : 'password'}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <RemoveRedEyeIcon color={showPassword ? 'primary' : undefined} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          sx={{ fontSize: '1em' }}
        />
      </div>
      {showNewPassword && (
        <div style={{ paddingTop: '.5em' }}>
          <TextField
            fullWidth
            type={showPassword ? undefined : 'password'}
            label="New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <RemoveRedEyeIcon color={showPassword ? 'primary' : undefined} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={newPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
            sx={{ fontSize: '1em' }}
          />
          <PasswordPolicyValidator password={newPassword} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '.5em',
        }}
      >
        <div style={{ flex: 1 }}>
          <Button
            variant="contained"
            disableElevation={true}
            fullWidth
            sx={{
              fontSize: '.8em',
              background: '#9ADAE7',
              '&:hover': {
                backgroundColor: '#5C9CB8',
              },
              borderRadius: '4rem',
            }}
            disabled={loading}
            onClick={handleLogin}
          >
            <div style={{ color: 'black' }}>Login</div>
          </Button>
        </div>
      </div>
      {whichLogin.includes('provider') && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '.5em',
          }}
        >
          <div style={{ flex: 1 }}>
            <Button
              variant="contained"
              disableElevation={true}
              fullWidth
              sx={{
                fontSize: '.8em',
                background: '#9ADAE7',
                '&:hover': {
                  backgroundColor: '#5C9CB8',
                },
                borderRadius: '4rem',
              }}
              onClick={() => navigate('/signup/provider')}
            >
              <div style={{ color: 'black' }}>Sign up as a Provider</div>
            </Button>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
}
