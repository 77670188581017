import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { Note, ProgressNoteProps, ReviewOfSystems } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { reviewOfSystems } from '../../common/Consts';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { ReviewNames } from '../../common/ReviewNames';

const ProgressNoteReviewOfSystems: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <ProgressNoteCollapse name="Review of Systems">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.reviewOfSystems && <ReviewNames values={progressNote.reviewOfSystems} />}
          <div className="flex">
            <PrimaryButton
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Start Review of Systems
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen} fullScreen>
          <div className="bg-primary-300">
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>Review of Systems</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="grid grid-cols-2 gap-3 w-full">
                <div className="grid grid-cols-3 gap-3">
                  <div className="flex flex-col">
                    <PartialRos
                      type="constitution"
                      progressNote={progressNote}
                      changeProgressNote={changeProgressNote}
                    />
                    <PartialRos type="hent" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                  </div>
                  <div className="flex flex-col">
                    <PartialRos type="eyes" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    <PartialRos type="cardio" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    <PartialRos
                      type="respiratory"
                      progressNote={progressNote}
                      changeProgressNote={changeProgressNote}
                    />
                    <PartialRos type="gi" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                  </div>
                  <div className="flex flex-col">
                    <PartialRos type="gu" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    <PartialRos type="muscle" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    <PartialRos type="skin" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="flex flex-col">
                      <PartialRos type="heme" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                      <PartialRos type="immuno" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                      <PartialRos
                        type="endocrine"
                        progressNote={progressNote}
                        changeProgressNote={changeProgressNote}
                      />
                    </div>
                    <div className="flex flex-col">
                      <PartialRos type="neuro" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    </div>
                    <div className="flex flex-col">
                      <PartialRos type="psych" progressNote={progressNote} changeProgressNote={changeProgressNote} />
                    </div>
                  </div>
                  <div className="flex w-full">
                    {progressNote.reviewOfSystems && <ReviewNames values={progressNote.reviewOfSystems} />}
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Accept</PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

interface PartialRosProps {
  type: keyof Omit<ReviewOfSystems, 'comment' | 'insertDate'>;
  progressNote: Note;
  changeProgressNote: (values: Partial<Note>) => void;
}

const PartialRos: React.FC<PartialRosProps> = ({ type, progressNote, changeProgressNote }) => {
  const [localRos, setLocalRos] = useState<string[]>(reviewOfSystems[type] ?? []);
  const [newValue, setNewValue] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);
  let data: string[] = [];
  if (progressNote.reviewOfSystems && progressNote.reviewOfSystems[type]) {
    data = progressNote.reviewOfSystems[type] ?? [];
  }

  if (!reviewOfSystems[type]) {
    return null;
  }

  const addHandler = (value: string) => {
    let newData = progressNote.reviewOfSystems ?? {};
    const index = newData[type]?.indexOf(value);
    if (!newData[type] || index === -1) {
      newData[type] = [...data, value];
      changeProgressNote({
        reviewOfSystems: newData,
      });
    }
  };
  const removeHandler = (value: string) => {
    let newData = { ...progressNote.reviewOfSystems };
    const index = newData[type]?.indexOf(value);

    if (index !== -1) {
      newData[type] = newData[type]?.filter((item, i) => i !== index);
      changeProgressNote({
        ...progressNote,
        reviewOfSystems: newData,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-1">
        <div className="font-semibold">{type.charAt(0).toUpperCase() + type.slice(1)}</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setEdit(true);
          }}
        >
          <AddCircleOutlineOutlinedIcon />
        </div>
      </div>

      <ul className="flex flex-col gap-1 py-2">
        {localRos.map((value, index) => {
          return (
            <li
              key={`${type}-${index}`}
              className={`grid grid-cols-8  items-center gap-1 rounded-full cursor-pointer ${
                progressNote.reviewOfSystems && progressNote.reviewOfSystems[type]?.includes(value)
                  ? 'bg-primary-500'
                  : 'bg-primary-100'
              }`}
            >
              <div className="font-bold text-right" onClick={() => addHandler(value)}>
                +
              </div>
              <div className="text-sm col-span-6 text-center">{value}</div>
              <div className="font-bold" onClick={() => removeHandler(value)}>
                -
              </div>
            </li>
          );
        })}
        {edit && (
          <div className="flex w-full gap-2 px-1 relative items-center">
            <input
              className="w-full rounded-xl px-2"
              type="text"
              value={newValue}
              placeholder="add new value"
              onChange={(e) => setNewValue(e.target.value)}
            />
            <button
              className="absolute right-3 font-bold"
              onClick={() => {
                setLocalRos([...localRos, newValue]);
                addHandler(newValue);
                setNewValue('');
                setEdit(false);
              }}
            >
              +
            </button>
          </div>
        )}
      </ul>
    </div>
  );
};

export default ProgressNoteReviewOfSystems;
