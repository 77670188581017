import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { FamilyHistory, ProgressNoteProps } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { generateRandomColorFromString, getInitials } from '../../common/functions';

const ProgressNoteFamilyHistories: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const [currentFamilyHistory, setCurrentFamilyHistory] = useState<FamilyHistory>({
    familyHistory: '',
    enteredBy: {
      ...progressNote.insertUser,
    },
    insertDate: new Date().toISOString(),
  });

  const resetCurrentFamilyHistory = () => {
    setCurrentFamilyHistory({
      familyHistory: '',
      enteredBy: {
        ...progressNote.insertUser,
      },
      insertDate: new Date().toISOString(),
    });
  };

  const addFamilyHistory = () => {
    if (!currentFamilyHistory.familyHistory) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    const prevState = progressNote.familyHistory ?? [];
    changeProgressNote({
      familyHistory: [currentFamilyHistory, ...prevState],
    });
    setDialogOpen(false);
    resetCurrentFamilyHistory();
  };

  return (
    <ProgressNoteCollapse name="Family History">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.familyHistory && progressNote.familyHistory.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border rounded border-gray-400">
              {progressNote.familyHistory?.map((familyHistory, index) => {
                if (showAll || index === 0)
                  return (
                    <li key={index} className="w-full p-2 flex gap-5">
                      <div>
                        <Avatar
                          sx={{
                            width: 96,
                            height: 96,
                            bgcolor: generateRandomColorFromString(familyHistory.enteredBy?.firstName),
                          }}
                          alt={familyHistory.enteredBy?.firstName || ''}
                        >
                          {getInitials(familyHistory.enteredBy?.firstName, familyHistory.enteredBy?.lastName)}
                        </Avatar>
                      </div>
                      <div className="flex flex-col w-full gap-5">
                        <div className="flex justify-between">
                          <p className="font-semibold">{`${familyHistory.enteredBy?.firstName} 
                        ${familyHistory.enteredBy?.lastName}`}</p>
                          <div>
                            {format(new Date(familyHistory?.insertDate || new Date().toISOString()), 'MM/dd/yyyy')}
                          </div>
                        </div>
                        <p>{familyHistory.familyHistory}</p>
                      </div>
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <div className="flex justify-end gap-5">
            {!showAll && progressNote.familyHistory && progressNote.familyHistory.length > 1 && (
              <PrimaryButton
                onClick={() => {
                  setShowAll(true);
                }}
                className="pl-2 pr-0"
              >
                <div className="flex">
                  <div>{`See All (${progressNote.familyHistory.length})`}</div>
                  <KeyboardArrowDownIcon />
                </div>
              </PrimaryButton>
            )}

            <PrimaryButton
              onClick={() => {
                resetCurrentFamilyHistory();
                setDialogOpen(true);
              }}
            >
              Add +
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen}>
          <div
            className="bg-primary-300"
            style={{
              width: '600px',
            }}
          >
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>{'Add Family History'}</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col gap-5">
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Family History *</label>
                  <textarea
                    className="bg-white border rounded p-2"
                    rows={4}
                    value={currentFamilyHistory.familyHistory}
                    placeholder="Enter family history.."
                    onChange={(e) =>
                      setCurrentFamilyHistory({ ...currentFamilyHistory, familyHistory: e.target.value })
                    }
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={addFamilyHistory}>{'Add'}</PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteFamilyHistories;
