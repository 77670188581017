import { configureStore } from '@reduxjs/toolkit';
import noteReducer from './slices/noteSlice';
import calendarReducer from './slices/calendarSlice';
import settingsReducer from './slices/settingsSlice';

const store = configureStore({
  reducer: {
    note: noteReducer,
    settings: settingsReducer,
    calendar: calendarReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
