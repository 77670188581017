import { useContext, useEffect, useState } from 'react';
import ScheduleHeader from '../components/providerDashboard/ScheduleHeader';
import ScheduleLeftPane from '../components/providerDashboard/ScheduleLeftPane';
import AppointmentsContainer from '../components/providerDashboard/appointments/AppointmentsContainer';
import Header from '../components/topbar/Header';
import { getAllPatients, getAppointments } from '../api';
import { PatientContext } from '../context/PatientContext';
import { Appointment } from '../types';

const ProviderDashboardPage: React.FC = () => {
  const { patients, setPatients } = useContext(PatientContext);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  useEffect(() => {
    async function fetchData() {
      if (patients.length === 0) setPatients(await getAllPatients());
      setAppointments(await getAppointments());
    }
    fetchData();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <Header />
      <ScheduleHeader />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100vh' }}>
        <ScheduleLeftPane appointments={appointments} />
        <AppointmentsContainer appointments={appointments} setAppointments={setAppointments} />
      </div>
    </div>
  );
};

export default ProviderDashboardPage;
