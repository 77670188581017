import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';

interface ProgressNoteCollapseProps {
  children: React.ReactNode;
  name: string;
  openByDefault?: boolean;
}

const ProgressNoteCollapse: React.FC<ProgressNoteCollapseProps> = ({ children, name, openByDefault }) => {
  const [open, setOpen] = useState<boolean>(openByDefault !== undefined ? openByDefault : false);

  return (
    <div className="border border-gray-300">
      <div
        className="flex justify-between background-primary-500 cursor-pointer py-2 px-4"
        onClick={() => setOpen(!open)}
      >
        <p className="font-bold">{name}</p>
        <KeyboardArrowDownIcon className={`${open ? 'rotate-180' : ''}`} />
      </div>
      <Collapse in={open} unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

export default ProgressNoteCollapse;
