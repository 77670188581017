import styles from './header.module.css';
import ProfileDownIcon from '../../assets/providerDashboardHeader/ProfileDownIcon.png';
import { useNavigate } from 'react-router';
import { useContext, useState } from 'react';
import { PatientContext } from '../../context/PatientContext';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { generateRandomColorFromString, getInitials } from '../common/functions';
import { getUser } from '../../api';

export default function ProfileHeader() {
  const { setPatients } = useContext(PatientContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = getUser();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <button
        className="relative"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar
          sx={{
            bgcolor: generateRandomColorFromString(user!.email),
          }}
        >
          {getInitials(user!.firstName || user!.email.charAt(0), user!.lastName || user!.email.charAt(1))}
        </Avatar>
        <img
          className={`${styles.profileDownIcon} absolute -bottom-1 -right-1 `}
          src={ProfileDownIcon}
          alt="Down Arrow Icon"
        />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/settings');
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPatients([]);
            sessionStorage.clear();
            navigate('/about-us');
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
