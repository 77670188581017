import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';

export type SearchBoxProps = {
  onSearch?: (query: string) => void;
};

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    if (onSearch !== undefined) {
      onSearch(query);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '40px',
        fontSize: '.5em',
        width: '500px',
        background: 'white',
        border: '1px solid #64748B',
        borderRadius: '4px',
      }}
    >
      <input
        id="headerSearch"
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search"
        style={{ border: 'none', outline: 'none', width: '480px' }}
      />
      <IconButton onClick={handleSearch}>
        <SearchIcon style={{ width: '16px', height: '16px' }} />
      </IconButton>
    </div>
  );
};

export default SearchBox;
