import { getPatientById, getPatientIdByEmail, getUser } from '../api';
import ProgressNoteContainer from '../components/providerEncounter/progress-note/ProgressNoteContainer';
import Header from '../components/topbar/Header';
import { useState, useEffect } from 'react';
import { Patient } from '../types';

export default function PatientDashboard() {
  const [patient, setPatient] = useState<Patient | null>(null);

  useEffect(() => {
    (async function fetch() {
      const patientId = await getPatientIdByEmail(getUser()!.email);
      if (!patientId) return;
      const res = await getPatientById(patientId);
      if (res) setPatient(res);
    })();
  }, []);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header />
      <div className="flex h-full w-full">
        {patient && <ProgressNoteContainer patient={patient} isPatient={true} />}
      </div>
    </div>
  );
}
