import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalendarState {
  userDate: UserDate;
}
export interface UserDate {
  selectedDate: number;
  displayedDate: number;
}

const initialState: CalendarState = {
  userDate: {
    selectedDate: Date.now(),
    displayedDate: Date.now(),
  },
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    changeUserDateState: (state, action: PayloadAction<Partial<UserDate>>) => {
      const newUserDate = {
        ...state.userDate,
        ...action.payload,
      };
      state.userDate = newUserDate;
    },
  },
});

export const { changeUserDateState } = calendarSlice.actions;
export default calendarSlice.reducer;
