import AboutUsPage from './pages/AboutUsPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ProviderDashboardPage from './pages/ProviderDashboardPage';
import { ProtectedRoute, PatientRouteRoute, ProviderRouteRoute } from './pages/router/ProtectedRoute';
import PatientsPage from './pages/PatientsPage';
import ProviderEncounterPage from './pages/ProviderEncounterPage';
import LoginPage from './pages/LoginPage';
import ProviderRegistration from './components/userRegistration/providerRegistration/ProviderRegistration';
import AddPatientForm from './components/patient/AddPatientForm';
import Settings from './pages/Settings';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledDiv } from './components/common/Consts';
import PatientDashboard from './pages/PatientDashboard';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AboutUsPage />,
  },
  {
    path: '/about-us',
    element: <AboutUsPage />,
  },
  {
    path: '/login/provider',
    element: <LoginPage whichLogin="provider" />,
  },
  {
    path: '/login/patient',
    element: <LoginPage whichLogin="patient" />,
  },
  {
    path: '/signup/provider',
    element: <ProviderRegistration />,
  },
  {
    path: '/dashboard-patient',
    element: (
      <PatientRouteRoute>
        <PatientDashboard />
      </PatientRouteRoute>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <ProviderRouteRoute>
        <ProviderDashboardPage />
      </ProviderRouteRoute>
    ),
  },
  {
    path: '/patients',
    element: (
      <ProviderRouteRoute>
        <PatientsPage />
      </ProviderRouteRoute>
    ),
  },
  {
    path: '/patients/register',
    element: (
      <ProviderRouteRoute>
        <AddPatientForm />
      </ProviderRouteRoute>
    ),
  },
  {
    path: '/encounter',
    element: (
      <ProviderRouteRoute>
        <ProviderEncounterPage />
      </ProviderRouteRoute>
    ),
  },
  {
    path: '/settings',
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
]);

const App: React.FC = () => {
  const { colorScheme } = useSelector((state: RootState) => state.settings);

  return (
    <StyledDiv $colorScheme={colorScheme}>
      <RouterProvider router={router} />
      <ToastContainer />
    </StyledDiv>
  );
};

export default App;
