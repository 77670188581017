import { createContext, useState } from 'react';
import { Patient } from '../types';

interface PatientContextProps {
  patients: Patient[];
  setPatients: (patients: Patient[]) => void;
}

export const PatientContext = createContext<PatientContextProps>({
  patients: [],
  setPatients: () => {},
});

export function PatientProvider({ children }: { children: React.ReactNode }) {
  const [patients, setPatients] = useState<Patient[]>([]);
  return <PatientContext.Provider value={{ patients, setPatients }}>{children}</PatientContext.Provider>;
}
