import { TextField } from '@mui/material';

export type BasicInfoTextFieldProps = {
  fieldLabel: string;
  fieldValue: string;
  onChangeCallback: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

const BasicInfoTextField: React.FC<BasicInfoTextFieldProps> = ({ fieldLabel, fieldValue, onChangeCallback }) => {
  return (
    <div style={{ paddingTop: '.5em' }}>
      <TextField
        fullWidth
        label={fieldLabel}
        sx={{ fontSize: '1em' }}
        value={fieldValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeCallback(e);
        }}
      />
    </div>
  );
};

export default BasicInfoTextField;
