import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsState {
  colorScheme: ColorScheme;
}
export interface ColorScheme {
  primaryColor100: string;
  primaryColor300: string;
  primaryColor500: string;
  primaryColor700: string;
}

export const initialSettingState: SettingsState = {
  colorScheme: {
    primaryColor100: '#E6F6F9',
    primaryColor300: '#D1EEF4',
    primaryColor500: '#9ADAE7',
    primaryColor700: '#0E79B2',
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingState,
  reducers: {
    changeColorSchemeState: (state, action: PayloadAction<Partial<ColorScheme>>) => {
      const newUserDate = {
        ...state.colorScheme,
        ...action.payload,
      };
      state.colorScheme = newUserDate;
    },
  },
});

export const { changeColorSchemeState } = settingsSlice.actions;
export default settingsSlice.reducer;
