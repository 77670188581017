import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, TextareaAutosize } from '@mui/material';
import { ProgressNoteProps, PastSurgicalHistory } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';

export const blankPastSurgicalHistory: PastSurgicalHistory = {
  surgery: '',
  date: new Date().toISOString(),
  comment: '',
  insertDate: new Date().toISOString(),
};

const ProgressNotePastSurgicalHistories: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [currentSurgicalHistory, setCurrentSurgicalHistory] = useState<PastSurgicalHistory>({
    ...blankPastSurgicalHistory,
    insertDate: new Date().toISOString(),
  });

  const resetCurrentSurgicalHistory = () => {
    setCurrentSurgicalHistory({
      ...blankPastSurgicalHistory,
      insertDate: new Date().toISOString(),
    });
  };

  const addSurgicalHistory = () => {
    if (!currentSurgicalHistory.surgery || !currentSurgicalHistory.date) {
      toast.error('Please fill required fields with Asterisk');
      return;
    }
    const prevState = progressNote.pastSurgicalHistories ?? [];
    changeProgressNote({
      pastSurgicalHistories: [...prevState, currentSurgicalHistory],
    });
    setDialogOpen(false);
    resetCurrentSurgicalHistory();
  };

  return (
    <ProgressNoteCollapse name="Past Surgical History">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.pastSurgicalHistories && progressNote.pastSurgicalHistories.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border rounded border-gray-400">
              {progressNote.pastSurgicalHistories?.map((surgicalHistory, index) => {
                return (
                  <li key={index} className="w-full px-2">
                    <div className="grid grid-cols-2 w-1/2">
                      <div className="text-blue-500">{surgicalHistory.surgery}</div>
                      <div>{format(new Date(surgicalHistory.date), 'MM/dd/yyyy')}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="flex justify-end">
            <PrimaryButton
              onClick={() => {
                resetCurrentSurgicalHistory();
                setDialogOpen(true);
              }}
            >
              Add Surgical History +
            </PrimaryButton>
          </div>
        </div>

        <Dialog open={dialogOpen}>
          <div
            className="bg-primary-300"
            style={{
              width: '600px',
            }}
          >
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>{'Add Surgical History'}</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col gap-5">
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Surgery</label>
                  <TextField
                    className="bg-white"
                    value={currentSurgicalHistory.surgery}
                    placeholder="Surgery *"
                    onChange={(e) => setCurrentSurgicalHistory({ ...currentSurgicalHistory, surgery: e.target.value })}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Date *</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="bg-white"
                      value={new Date(currentSurgicalHistory.date)}
                      onChange={(date: Date | null) => {
                        if (date != null) {
                          setCurrentSurgicalHistory({ ...currentSurgicalHistory, date: date.toISOString() });
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full flex flex-col">
                  <label className="font-semibold">Comment</label>
                  <TextareaAutosize
                    style={{ padding: 10 }}
                    minRows={3}
                    className="bg-white"
                    value={currentSurgicalHistory.comment}
                    placeholder="Comment"
                    onChange={(e) => setCurrentSurgicalHistory({ ...currentSurgicalHistory, comment: e.target.value })}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={addSurgicalHistory}>{'Add'}</PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNotePastSurgicalHistories;
