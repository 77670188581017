import { Avatar, Box, CircularProgress, Dialog } from '@mui/material';
import PrimaryButton from '../components/custom/PrimaryButton';
import Header from '../components/topbar/Header';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { calculateAgebyBirthdate, formatPhoneNumber, getInitials } from '../components/common/functions';
import { PatientContext } from '../context/PatientContext';
import { getAllPatients, getBlob, invitePatientToFilum } from '../api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailIcon from '@mui/icons-material/Mail';
import { Patient } from '../types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { generateRandomColorFromString } from '../components/common/functions';
import { toast } from 'react-toastify';

const PatientsPage: React.FC = () => {
  const { patients, setPatients } = useContext(PatientContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [insuranceFrontUrl, setInsuranceFrontUrl] = useState<string | null>(null);
  const [insuranceBackUrl, setInsuranceBackUrl] = useState<string | null>(null);
  const [currentPatient, setCurrentPatiant] = useState<Patient | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async function fetchPatients() {
      if (patients.length === 0) setPatients(await getAllPatients());
    })();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'profile',
      headerName: 'Profile',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex justify-end">
            <Avatar
              sx={{
                bgcolor: generateRandomColorFromString(params.row.firstName),
              }}
            >
              {getInitials(params.row.firstName, params.row.lastName)}
            </Avatar>
          </div>
        );
      },
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      flex: 2,
      sortable: true,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      sortable: true,
      renderCell: (params) => <div>{calculateAgebyBirthdate(params.row.dateOfBirth)}</div>,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      sortable: true,
      flex: 1,
    },
    {
      field: 'mobilePhone',
      headerName: 'Phone',
      flex: 2,
      sortable: true,
      renderCell: (params) => <div>{formatPhoneNumber(params.row.mobilePhone)}</div>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <div className="flex justify-center gap-3">
          <PrimaryButton
            onClick={() => {
              navigate('/encounter', {
                state: {
                  patient: params.row,
                },
              });
            }}
          >
            Encounter
          </PrimaryButton>
          <PrimaryButton
            onClick={async () => {
              setDialogOpen(true);
              setCurrentPatiant(params.row);
              setInsuranceFrontUrl(await getBlob(params.row.insuranceCardFrontBlobId ?? '', 'insurance'));
              setInsuranceBackUrl(await getBlob(params.row.insuranceCardBackBlobId ?? '', 'insurance'));
            }}
          >
            <div className="flex gap-2 items-center">
              <VisibilityIcon />
              Insurance
            </div>
          </PrimaryButton>
          <PrimaryButton
            onClick={async () => {
              if (await invitePatientToFilum(params.row.email)) {
                toast.success('Successfully invited the patient');
              } else {
                toast.error('Sorry, something went wrong');
              }
            }}
          >
            <div className="flex gap-2 items-center">
              <MailIcon />
              Invite
            </div>
          </PrimaryButton>
          {/* It can prefill the form. Discuss and decide what fields are mutable.
          And implement the updateItem preventing overwrite. */}
          {/* <PrimaryButton
            onClick={() => {
              navigate('/patients/register', {
                state: {
                  patient: params.row,
                },
              });
            }}
          >
            Edit
          </PrimaryButton> */}
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col overflow-hidden background-primary-300 min-h-screen">
      <Header />
      <div className="flex flex-col h-full w-full">
        <div className="flex justify-between items-center p-4">
          <h1 className="text-2xl font-bold">Patient List</h1>
          <PrimaryButton
            onClick={() => {
              navigate('/patients/register');
            }}
          >
            Create New Patient
          </PrimaryButton>
        </div>
        <div className="flex w-full p-4 flex-col">
          <Box sx={{ width: '100%' }} className="bg-white">
            <DataGrid
              getRowId={(row) => row.patientId}
              rows={patients}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setInsuranceFrontUrl(null);
              setInsuranceBackUrl(null);
            }}
          >
            {currentPatient && (
              <div className="grid grid-cols-2 p-5 gap-5">
                <div>
                  <center>Front of Card</center>
                  {insuranceFrontUrl ? (
                    <img src={insuranceFrontUrl} alt={`${currentPatient.firstName} insurance`} />
                  ) : (
                    <CircularProgress />
                  )}
                </div>
                <div>
                  <center>Back of Card</center>
                  {insuranceBackUrl ? (
                    <img src={insuranceBackUrl} alt={`${currentPatient.firstName} insurance`} />
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default PatientsPage;
