import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { ProgressNoteProps, TemperatureDegree, VitalSigns } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { celsiusToFahrenheit, cmToInch, fahrenheitToCelsius, inchToCm, kgToLbs, lbsToKg } from '../../common/functions';
import { ReviewNamesVital } from '../../common/ReviewNames';

const ProgressNoteVitalSigns: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [vitalSigns, setVitalSigns] = useState<VitalSigns>(progressNote.vitalSigns ?? {});

  return (
    <ProgressNoteCollapse name="Vital Signs">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          <div className="flex gap-2">
            {progressNote.vitalSigns && <ReviewNamesVital values={progressNote.vitalSigns} />}
          </div>
          <div className="flex">
            <PrimaryButton
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Enter Vital Signs
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen} fullScreen>
          <div className="bg-primary-300">
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>Vital Signs</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col gap-3 w-full">
                <div className="flex flex-col ">
                  <label className="font-semibold">Temperature</label>
                  <div className="flex gap-3 items-end">
                    <input
                      type="number"
                      className="bg-white py-2 px-2 rounded border border-gray-400"
                      style={{
                        maxWidth: '150px',
                      }}
                      step="0.1"
                      placeholder="Temperature"
                      value={
                        vitalSigns.temperature?.temperature === undefined ? '' : vitalSigns.temperature?.temperature
                      }
                      onChange={(e) => {
                        let temperature = vitalSigns.temperature ?? {
                          temperature: 0,
                          degree: TemperatureDegree.F,
                          selected: '',
                        };
                        temperature.temperature = parseFloat(e.target.value);
                        setVitalSigns({
                          ...vitalSigns,
                          temperature,
                        });
                      }}
                    />
                    <div className="flex gap-3">
                      <div>
                        <button
                          onClick={() => {
                            let temperature = vitalSigns.temperature ?? {
                              temperature: 0,
                              degree: TemperatureDegree.F,
                              selected: '',
                            };
                            if (
                              vitalSigns.temperature?.temperature &&
                              vitalSigns.temperature.degree === TemperatureDegree.C
                            ) {
                              temperature.temperature = celsiusToFahrenheit(vitalSigns.temperature.temperature);
                            }
                            temperature.degree = TemperatureDegree.F;
                            setVitalSigns({
                              ...vitalSigns,
                              temperature,
                            });
                          }}
                          className={`px-2 text-sm rounded border border-gray-400 border-r-0 shadow ${
                            vitalSigns.temperature?.degree === TemperatureDegree.C ? 'bg-white' : 'bg-primary-500'
                          }`}
                        >
                          °F
                        </button>
                        <button
                          onClick={() => {
                            let temperature = vitalSigns.temperature ?? {
                              temperature: 0,
                              degree: TemperatureDegree.C,
                              selected: '',
                            };
                            if (
                              vitalSigns.temperature?.temperature &&
                              vitalSigns.temperature.degree === TemperatureDegree.F
                            ) {
                              temperature.temperature = fahrenheitToCelsius(vitalSigns.temperature.temperature);
                            }
                            temperature.degree = TemperatureDegree.C;
                            setVitalSigns({
                              ...vitalSigns,
                              temperature,
                            });
                          }}
                          className={`px-2 text-sm rounded border border-gray-400 shadow ${
                            vitalSigns.temperature?.degree === TemperatureDegree.C ? 'bg-primary-500' : 'bg-white'
                          }`}
                        >
                          °C
                        </button>
                      </div>
                      {['Oral', 'Ear', 'Axillary', 'Rectal', 'Temporal'].map((value) => {
                        return (
                          <button
                            key={value}
                            className={`radioButton ${
                              vitalSigns.temperature?.selected === value ? 'bg-primary-500' : 'bg-primary-100'
                            } `}
                            onClick={() => {
                              let temperature = vitalSigns.temperature ?? {
                                temperature: 0,
                                degree: TemperatureDegree.F,
                                selected: '',
                              };
                              if (temperature.selected === value) {
                                temperature.selected = '';
                              } else {
                                temperature.selected = value;
                              }
                              setVitalSigns({
                                ...vitalSigns,
                                temperature,
                              });
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <RowForm
                  name="Heart Rate"
                  selectData={['Regular', 'Regularly Irregular', 'Irregular', 'Irregularly Irregular']}
                  textValue={vitalSigns.heartRate?.rate}
                  selectedValue={vitalSigns.heartRate?.selected}
                  onTextChange={(value: string) => {
                    let heartRate = vitalSigns.heartRate ?? {
                      rate: 0,
                      selected: '',
                    };
                    heartRate.rate = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      heartRate,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let heartRate = vitalSigns.heartRate ?? {
                      rate: 0,
                      selected: '',
                    };
                    if (heartRate.selected === selected) {
                      heartRate.selected = '';
                    } else {
                      heartRate.selected = selected;
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      heartRate,
                    });
                  }}
                />
                <RowForm
                  name="Respiration Rate"
                  selectData={['12 resp/min', '16 resp/min', '20 resp/min', '24 resp/min']}
                  textValue={vitalSigns.respirationRate?.rate}
                  selectedValue={vitalSigns.respirationRate?.selected}
                  onTextChange={(value: string) => {
                    let respirationRate = vitalSigns.respirationRate ?? {
                      rate: 0,
                      selected: '',
                    };
                    respirationRate.selected = undefined;
                    respirationRate.rate = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      respirationRate,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let respirationRate = vitalSigns.respirationRate ?? {
                      rate: 0,
                      selected: '',
                    };
                    respirationRate.rate = undefined;
                    if (respirationRate.selected === selected) {
                      respirationRate.selected = undefined;
                    } else {
                      respirationRate.selected = parseInt(selected).toString();
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      respirationRate,
                    });
                  }}
                />
                {[0, 1, 2].map((index) => {
                  return (
                    <BloodPressureForm
                      key={`blood-pressure-${index}`}
                      index={index}
                      name="Blood Pressure"
                      selectData={[
                        'Left',
                        'Right',
                        'Arm',
                        'Wrist',
                        'Leg',
                        'Supine',
                        'Sitting',
                        'Standing',
                        'Prone',
                        'On Side',
                      ]}
                      textValue={vitalSigns.bloodPressure?.[index]?.pressure || ''}
                      selectedValues={vitalSigns.bloodPressure?.[index]?.selected || []}
                      onTextChange={(value: string) => {
                        let updatedBloodPressure = vitalSigns.bloodPressure ?? [
                          {
                            pressure: '',
                            selected: [],
                          },
                          {
                            pressure: '',
                            selected: [],
                          },
                          {
                            pressure: '',
                            selected: [],
                          },
                        ];
                        updatedBloodPressure[index].pressure = value;
                        setVitalSigns({
                          ...vitalSigns,
                          bloodPressure: updatedBloodPressure,
                        });
                      }}
                      onSelectedChange={(selected: string) => {
                        let updatedBloodPressure = vitalSigns.bloodPressure ?? [
                          {
                            pressure: '',
                            selected: [],
                          },
                          {
                            pressure: '',
                            selected: [],
                          },
                          {
                            pressure: '',
                            selected: [],
                          },
                        ];

                        if (updatedBloodPressure[index]?.selected.includes(selected)) {
                          updatedBloodPressure[index].selected = updatedBloodPressure[index].selected.filter(
                            (item) => item !== selected
                          );
                        } else {
                          updatedBloodPressure[index].selected = updatedBloodPressure[index].selected.filter((item) => {
                            if (selected === 'Left' || selected === 'Right') {
                              return item !== 'Left' && item !== 'Right';
                            } else if (selected === 'Arm' || selected === 'Wrist' || selected === 'Leg') {
                              return item !== 'Arm' && item !== 'Wrist' && item !== 'Leg';
                            } else if (
                              selected === 'Supine' ||
                              selected === 'Sitting' ||
                              selected === 'Standing' ||
                              selected === 'Prone' ||
                              selected === 'On Side'
                            ) {
                              return (
                                item !== 'Supine' &&
                                item !== 'Sitting' &&
                                item !== 'Standing' &&
                                item !== 'Prone' &&
                                item !== 'On Side'
                              );
                            }
                            return false;
                          });
                          updatedBloodPressure[index].selected.push(selected);
                        }
                        setVitalSigns({
                          ...vitalSigns,
                          bloodPressure: updatedBloodPressure,
                        });
                      }}
                    />
                  );
                })}
                <RowFormMultiSelect
                  name="Height"
                  selectData={['cm', 'inches', 'Stated', 'Supine', 'Standing']}
                  textValue={vitalSigns.height?.height}
                  selectedValues={vitalSigns.height?.selected}
                  onTextChange={(value: string) => {
                    let height = vitalSigns.height ?? {
                      height: 0,
                      selected: [],
                    };
                    height.height = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      height,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let height = vitalSigns.height ?? {
                      height: 0,
                      selected: [''],
                    };
                    if (
                      selected === 'inches' &&
                      vitalSigns.height &&
                      vitalSigns.height.height > 0 &&
                      vitalSigns.height.selected.includes('cm')
                    ) {
                      height.height = cmToInch(height.height);
                    } else if (
                      selected === 'cm' &&
                      vitalSigns.height &&
                      vitalSigns.height.height > 0 &&
                      vitalSigns.height.selected.includes('inches')
                    ) {
                      height.height = inchToCm(height.height);
                    }
                    if (height.selected.includes(selected)) {
                      height.selected = height.selected.filter((item) => item !== selected);
                    } else {
                      height.selected = height.selected.filter((item) => {
                        if (selected === 'cm' || selected === 'inches') {
                          return item !== 'cm' && item !== 'inches';
                        } else if (selected === 'Supine' || selected === 'Standing') {
                          return item !== 'Supine' && item !== 'Standing';
                        }
                        return true;
                      });
                      height.selected.push(selected);
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      height,
                    });
                  }}
                />
                <RowFormMultiSelect
                  name="Weight"
                  selectData={['kg', 'lbs', 'Stated', 'With Clothes', 'No Clothes', 'Pre-Dialysis', 'Post-Dialysis']}
                  textValue={vitalSigns.weight?.weight}
                  selectedValues={vitalSigns.weight?.selected}
                  onTextChange={(value: string) => {
                    let weight = vitalSigns.weight ?? {
                      weight: 0,
                      selected: [],
                    };
                    weight.weight = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      weight,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let weight = vitalSigns.weight ?? {
                      weight: 0,
                      selected: [],
                    };
                    if (
                      selected === 'kg' &&
                      vitalSigns.weight &&
                      vitalSigns.weight.weight > 0 &&
                      vitalSigns.weight.selected.includes('lbs')
                    ) {
                      weight.weight = lbsToKg(weight.weight);
                    } else if (
                      selected === 'lbs' &&
                      vitalSigns.weight &&
                      vitalSigns.weight.weight > 0 &&
                      vitalSigns.weight.selected.includes('kg')
                    ) {
                      weight.weight = kgToLbs(weight.weight);
                    }
                    if (weight.selected.includes(selected)) {
                      weight.selected = weight.selected.filter((item) => item !== selected);
                    } else {
                      weight.selected = weight.selected.filter((item) => {
                        if (selected === 'kg' || selected === 'lbs') {
                          return item !== 'kg' && item !== 'lbs';
                        } else if (selected === 'With Clothes' || selected === 'No Clothes') {
                          return item !== 'With Clothes' && item !== 'No Clothes';
                        } else if (selected === 'Pre-Dialysis' || selected === 'Post-Dialysis') {
                          return item !== 'Pre-Dialysis' && item !== 'Post-Dialysis';
                        }
                        return true;
                      });
                      weight.selected.push(selected);
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      weight,
                    });
                  }}
                />
                <RowForm
                  name="Oxygenation"
                  selectData={[
                    'Room Air (Rest)',
                    'Oxygen',
                    'Breathin Tx',
                    'Room Air (O2)',
                    'Room Air (Exercise)',
                    'Ambulate (O2)',
                    'Ambulate (Room Air)',
                    'CPAP/BiPAP',
                  ]}
                  textValue={vitalSigns.oxygenation?.oxygenation}
                  selectedValue={vitalSigns.oxygenation?.selected}
                  onTextChange={(value: string) => {
                    let oxygenation = vitalSigns.oxygenation ?? {
                      oxygenation: 0,
                      selected: '',
                    };
                    oxygenation.oxygenation = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      oxygenation,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let oxygenation = vitalSigns.oxygenation ?? {
                      oxygenation: 0,
                      selected: '',
                    };
                    if (oxygenation.selected === selected) {
                      oxygenation.selected = '';
                    } else {
                      oxygenation.selected = selected;
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      oxygenation,
                    });
                  }}
                />
                <RowFormVision
                  name="Vision OD"
                  selectData={[
                    '20/10',
                    '20/15',
                    '20/20',
                    '20/25',
                    '20/30',
                    '20/40',
                    '20/50',
                    '20/60',
                    '20/70',
                    '20/80',
                    '20/100',
                    '20/200',
                    '20/400',
                  ]}
                  selectedValue={vitalSigns.visionOd?.selected ?? ''}
                  onSelectedChange={(selected: string) => {
                    let visionOd = vitalSigns.visionOd ?? {
                      selected: '',
                    };
                    if (visionOd.selected === selected) {
                      visionOd.selected = '';
                    } else {
                      visionOd.selected = selected;
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      visionOd,
                    });
                  }}
                  withCorrection={vitalSigns.visionOd?.withCorrect}
                  setWithCorrection={(withCorrection) => {
                    let visionOd = vitalSigns.visionOd ?? {
                      selected: '',
                      withCorrect: undefined,
                    };

                    visionOd.withCorrect = withCorrection;

                    setVitalSigns({
                      ...vitalSigns,
                      visionOd,
                    });
                  }}
                />
                <RowFormVision
                  name="Vision OS"
                  selectData={[
                    '20/10',
                    '20/15',
                    '20/20',
                    '20/25',
                    '20/30',
                    '20/40',
                    '20/50',
                    '20/60',
                    '20/70',
                    '20/80',
                    '20/100',
                    '20/200',
                    '20/400',
                  ]}
                  selectedValue={vitalSigns.visionOs?.selected ?? ''}
                  onSelectedChange={(selected: string) => {
                    let visionOs = vitalSigns.visionOs ?? {
                      selected: '',
                    };
                    if (visionOs.selected === selected) {
                      visionOs.selected = '';
                    } else {
                      visionOs.selected = selected;
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      visionOs,
                    });
                  }}
                  withCorrection={vitalSigns.visionOs?.withCorrect}
                  setWithCorrection={(withCorrection) => {
                    let visionOs = vitalSigns.visionOs ?? {
                      selected: '',
                      withCorrect: undefined,
                    };

                    visionOs.withCorrect = withCorrection;

                    setVitalSigns({
                      ...vitalSigns,
                      visionOs,
                    });
                  }}
                />
                <RowForm
                  name="Head Circumference"
                  selectData={['cm', 'inches']}
                  textValue={vitalSigns.headCircumference?.headCircumference}
                  selectedValue={vitalSigns.headCircumference?.selected}
                  onTextChange={(value: string) => {
                    let headCircumference = vitalSigns.headCircumference ?? {
                      headCircumference: 0,
                      selected: '',
                    };
                    headCircumference.headCircumference = parseFloat(value);
                    setVitalSigns({
                      ...vitalSigns,
                      headCircumference,
                    });
                  }}
                  onSelectedChange={(selected: string) => {
                    let headCircumference = vitalSigns.headCircumference ?? {
                      headCircumference: 0,
                      selected: '',
                    };
                    if (
                      selected === 'inches' &&
                      vitalSigns.headCircumference &&
                      vitalSigns.headCircumference.headCircumference > 0 &&
                      vitalSigns.headCircumference.selected === 'cm'
                    ) {
                      headCircumference.headCircumference = cmToInch(headCircumference.headCircumference);
                    } else if (
                      selected === 'cm' &&
                      vitalSigns.headCircumference &&
                      vitalSigns.headCircumference.headCircumference > 0 &&
                      vitalSigns.headCircumference.selected === 'inches'
                    ) {
                      headCircumference.headCircumference = inchToCm(headCircumference.headCircumference);
                    }
                    if (headCircumference.selected === selected) {
                      headCircumference.selected = '';
                    } else {
                      headCircumference.selected = selected;
                    }
                    setVitalSigns({
                      ...vitalSigns,
                      headCircumference,
                    });
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  changeProgressNote({
                    ...progressNote,
                    vitalSigns: vitalSigns,
                  });
                  setDialogOpen(false);
                }}
              >
                Accept
              </PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

interface RowFormVisionProps {
  onSelectedChange: (value: string) => void;
  name: string;
  selectData: string[];
  selectedValue?: string;
  withCorrection?: boolean;
  setWithCorrection: (value: boolean) => void;
}

const RowFormVision: React.FC<RowFormVisionProps> = ({
  onSelectedChange,
  name,
  selectData,
  selectedValue,
  withCorrection,
  setWithCorrection,
}) => {
  return (
    <div className="flex flex-col ">
      <label className="font-semibold">{name}</label>
      <div className="flex items-end gap-3">
        <button
          className={`radioButton ${withCorrection === true ? 'bg-primary-500' : 'bg-primary-100'} `}
          onClick={() => {
            setWithCorrection(true);
          }}
        >
          With correction
        </button>
        <button
          className={`radioButton ${withCorrection === false ? 'bg-primary-500' : 'bg-primary-100'} `}
          onClick={() => {
            setWithCorrection(false);
          }}
        >
          Without correction
        </button>
      </div>
      <div className="flex gap-3 mt-1">
        {selectData.map((value) => {
          return (
            <button
              key={value}
              className={`radioButton ${selectedValue === value ? 'bg-primary-500' : 'bg-primary-100'} `}
              onClick={() => {
                onSelectedChange(value);
              }}
            >
              {value}
            </button>
          );
        })}
      </div>
    </div>
  );
};

interface RowFormMultiSelectProps {
  onTextChange: (e: string) => void;
  onSelectedChange: (value: string) => void;
  name: string;
  selectData: string[];
  selectedValues?: string[];
  textValue?: number;
}

const RowFormMultiSelect: React.FC<RowFormMultiSelectProps> = ({
  onTextChange,
  onSelectedChange,
  name,
  selectData,
  textValue,
  selectedValues,
}) => {
  return (
    <div className="flex flex-col ">
      <label className="font-semibold">{name}</label>
      <div className="flex gap-3 items-end">
        <input
          type="number"
          className="bg-white py-2 px-2 rounded border border-gray-400"
          style={{
            maxWidth: '150px',
          }}
          step="0.1"
          placeholder={name}
          value={textValue || ''}
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
        />
        <div className="flex gap-3">
          {selectData.map((value) => {
            return (
              <button
                key={value}
                className={`radioButton ${selectedValues?.includes(value) ? 'bg-primary-500' : 'bg-primary-100'} `}
                onClick={() => {
                  onSelectedChange(value);
                }}
              >
                {value}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface RowFormProps {
  onTextChange: (e: string) => void;
  onSelectedChange: (value: string) => void;
  name: string;
  selectData: string[];
  selectedValue?: string;
  textValue?: number;
}

const RowForm: React.FC<RowFormProps> = ({
  onTextChange,
  onSelectedChange,
  name,
  selectData,
  textValue,
  selectedValue,
}) => {
  return (
    <div className="flex flex-col ">
      <label className="font-semibold">{name}</label>
      <div className="flex gap-3 items-end">
        <input
          type="number"
          className="bg-white py-2 px-2 rounded border border-gray-400"
          style={{
            maxWidth: '150px',
          }}
          step="0.1"
          placeholder={name}
          value={textValue || ''}
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
        />
        <div className="flex gap-3">
          {selectData.map((value) => {
            return (
              <button
                key={value}
                className={`radioButton ${
                  selectedValue === value || (selectedValue && parseInt(selectedValue) === parseInt(value))
                    ? 'bg-primary-500'
                    : 'bg-primary-100'
                } `}
                onClick={() => {
                  onSelectedChange(value);
                }}
              >
                {value}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface BloodPressureFormProps {
  onTextChange: (e: string) => void;
  onSelectedChange: (value: string) => void;
  name: string;
  selectData: string[];
  selectedValues?: string[];
  textValue?: string;
  index: number;
}

const BloodPressureForm: React.FC<BloodPressureFormProps> = ({
  onTextChange,
  onSelectedChange,
  name,
  selectData,
  textValue,
  selectedValues,
  index,
}) => {
  return (
    <div className="flex flex-col ">
      {index === 0 && <label className="font-semibold">{name}</label>}
      <div className="flex gap-3 items-end">
        <input
          type="text"
          className="bg-white py-2 px-2 rounded border border-gray-400"
          style={{
            maxWidth: '150px',
          }}
          step="0.1"
          placeholder={name}
          value={textValue || ''}
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
        />
        <div className="flex gap-3">
          {selectData.map((value) => {
            return (
              <button
                key={value}
                className={`radioButton ${selectedValues?.includes(value) ? 'bg-primary-500' : 'bg-primary-100'} `}
                onClick={() => {
                  onSelectedChange(value);
                }}
              >
                {value}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default ProgressNoteVitalSigns;
