import ProviderImage from '../assets/ProviderLoginImage.png';
import PatientImage from '../assets/PatientLoginImage.svg';
import GuestTopBar from '../components/common/GuestTopBar';
import useWindowResize from '../hooks/useWindowResize';
import Copyright from '../components/common/Copyright';
import LoginForm from '../components/auth/LoginForm';

export default function LoginPage({ whichLogin }: { whichLogin: string }) {
  const { windowWidth } = useWindowResize();
  const isSmallScreen = windowWidth < 800;

  const imageToDisplay = whichLogin === 'patient' ? PatientImage : ProviderImage;
  const imageAltText = whichLogin === 'patient' ? 'Patient Login' : 'Provider Login';

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          padding: '1em',
        }}
      >
        {isSmallScreen ? (
          <></>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={imageToDisplay} alt={imageAltText} style={{ height: '85vh', objectFit: 'cover' }} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '50%',
            paddingLeft: '.5em',
          }}
        >
          <GuestTopBar selectedOption={whichLogin} />
          <LoginForm whichLogin={whichLogin} />
          <div style={{ marginTop: '1em' }}>
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
}
