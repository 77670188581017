import { useState } from 'react';
import { Button, Box } from '@mui/material';
import FilumLogo from '../../assets/FilumLogo.png';
import { useNavigate } from 'react-router';

export default function GuestTopBar({ selectedOption }: { selectedOption: string }) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(selectedOption);

  return (
    <div style={{ flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            navigate('/login/provider');
            setSelected('provider');
          }}
          style={{
            fontSize: '.8em',
            textDecoration: selected === 'provider' ? 'underline' : 'none',
          }}
        >
          Provider Login
        </Button>
        <Button
          onClick={() => {
            navigate('/login/patient');
            setSelected('patient');
          }}
          style={{
            fontSize: '.8em',
            textDecoration: selected === 'patient' ? 'underline' : 'none',
          }}
        >
          Patient Login
        </Button>
        <Button
          onClick={() => {
            navigate('/about-us');
            setSelected('about');
          }}
          style={{
            fontSize: '.8em',
            textDecoration: selected === 'about' ? 'underline' : 'none',
          }}
        >
          About FilumHealth
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Box component="img" sx={{ width: '30vw', maxWidth: '500px' }} alt="FilumHealth" src={FilumLogo} />
      </div>
    </div>
  );
}
