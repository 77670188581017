import { useState, useEffect, useContext } from 'react';
import AppointmentCard from './ApointmentCard';
import { addMinutes, set } from 'date-fns';
import { Box, CircularProgress, Collapse, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import { PatientContext } from '../../../context/PatientContext';
import { getAllPatients, getUser, storeAppointment, updateAppointmentStatus } from '../../../api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Appointment, AppointmentStatus } from '../../../types';
import { formatPhoneNumber } from '../../common/functions';
import { calculateAgebyBirthdate } from '../../common/functions';
import PrimaryButton from '../../custom/PrimaryButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { toast } from 'react-toastify';
import Loader from '../../common/Loadert';

const blankAppointment: Appointment = {
  startDate: new Date().getTime(),
  appointmentStatus: AppointmentStatus.Scheduled,
  providerId: getUser()?.id ?? '',
  reason: '',
};

export default function AppointmentsContainer({
  appointments,
  setAppointments,
}: {
  appointments: Appointment[];
  setAppointments: (appointments: Appointment[]) => void;
}) {
  const [appointmentsList, setAppointmentsList] = useState<JSX.Element[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { patients, setPatients } = useContext(PatientContext);
  const userDateProps = useSelector((state: RootState) => state.calendar.userDate);
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>(blankAppointment);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function fetchData() {
      if (patients.length === 0) {
        setLoading(true);
        setPatients(await getAllPatients());
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setAppointmentsList(buildAppointments());
  }, [appointments, userDateProps.selectedDate]);

  const schedulePatient = (startDate: Date): void => {
    setCurrentAppointment({
      ...blankAppointment,
      startDate: startDate.getTime(),
      endDate: addMinutes(startDate, 30).getTime(),
    });
    setDialogOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Full name',
      flex: 2,
      sortable: true,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      sortable: true,
      renderCell: (params) => <div>{calculateAgebyBirthdate(params.row.dateOfBirth)}</div>,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      sortable: true,
      flex: 1,
    },
    {
      field: 'mobilePhone',
      headerName: 'Phone',
      flex: 2,
      sortable: true,
      renderCell: (params) => <div>{formatPhoneNumber(params.row.mobilePhone)}</div>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <div className="flex justify-center gap-3">
          <PrimaryButton
            onClick={() => {
              const patient = patients.find((p) => p.patientId === params.row.patientId);
              setCurrentAppointment({
                ...currentAppointment,
                patient,
              });
            }}
          >
            Select
          </PrimaryButton>
        </div>
      ),
    },
  ];

  const buildAppointments = () => {
    let appointmentTime = new Date(userDateProps.selectedDate);

    appointmentTime = set(appointmentTime, {
      hours: 7,
      minutes: 30,
      seconds: 0,
      milliseconds: 0,
    });

    const getNextAppointment = () => {
      appointmentTime = addMinutes(appointmentTime, 30);
      return appointmentTime;
    };

    const appointmentsList: JSX.Element[] = [];
    let _key = 0;
    let startTime = getNextAppointment();

    while (appointmentTime.getHours() < 16) {
      const endTime = addMinutes(startTime, 30);
      let currentAppointment: Appointment | null = null;

      for (const appointment of appointments) {
        if (startTime.getTime() <= appointment.startDate && appointment.startDate < endTime.getTime()) {
          currentAppointment = appointment;
          break;
        }
      }

      appointmentsList.push(
        <AppointmentCard
          key={_key}
          timeSlot={startTime}
          appointment={currentAppointment}
          schedulePatient={schedulePatient}
          updateAppointment={async (appointment: Appointment) => {
            const result: boolean = await updateAppointmentStatus(appointment);
            const newAppointments = appointments.filter((obj) => obj.startDate !== appointment.startDate);
            if (appointment.appointmentStatus !== AppointmentStatus.Cancelled) {
              newAppointments.push(appointment);
            }
            setAppointments(newAppointments);
            if (result) {
              toast.success('Successfully update the appointment');
            } else {
              toast.error('Failed to save');
            }
          }}
        />
      );

      _key++;
      startTime = getNextAppointment();
    }
    return appointmentsList;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '85%',
        overflowY: 'auto',
      }}
    >
      {appointmentsList}
      {loading && <Loader />}
      <Dialog open={dialogOpen} fullWidth maxWidth="md">
        <div className="bg-primary-300">
          <DialogTitle>Select a patient</DialogTitle>
          <div className="h-full px-4">
            <Box sx={{ width: '100%' }} className="bg-white">
              <DataGrid
                getRowId={(row) => row.patientId}
                rows={patients}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
              <Collapse in={currentAppointment?.patient ? true : false} unmountOnExit>
                <TextField
                  className="w-full"
                  placeholder="Reason"
                  value={currentAppointment?.reason}
                  onChange={(e) => {
                    setCurrentAppointment({
                      ...currentAppointment,
                      reason: e.target.value,
                    });
                  }}
                />
              </Collapse>
            </Box>
          </div>
          <DialogActions>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <PrimaryButton
                  onClick={async () => {
                    setLoading(true);
                    if (currentAppointment) {
                      const holdAppointment = {
                        ...currentAppointment,
                        appointmentStatus: AppointmentStatus.Held,
                        patient: undefined,
                      };
                      const result: boolean = await storeAppointment(holdAppointment);
                      if (result) {
                        setAppointments([...appointments, holdAppointment]);
                        setCurrentAppointment(blankAppointment);
                        toast.success('Success');
                      } else {
                        toast.error('Failed to save');
                      }
                    } else {
                      toast.error('Please fill informations');
                    }
                    setLoading(false);
                    setDialogOpen(false);
                  }}
                >
                  Hold Time
                </PrimaryButton>
                <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
                <PrimaryButton
                  onClick={async () => {
                    setLoading(true);
                    if (currentAppointment && currentAppointment.patient) {
                      const result: boolean = await storeAppointment(currentAppointment);
                      if (result) {
                        setAppointments([...appointments, currentAppointment]);
                        setCurrentAppointment(blankAppointment);
                        toast.success('Success');
                      } else {
                        toast.error('Failed to save');
                      }
                    } else {
                      toast.error('Please fill informations');
                    }
                    setLoading(false);
                    setDialogOpen(false);
                  }}
                >
                  Schedule
                </PrimaryButton>
              </>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
