import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Medication, ProgressNoteProps } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { blankMedication } from '../../common/Consts';

const ProgressNoteMedications: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [medicationToEdit, setMedicationToEdit] = useState<number | null>(null);
  const [currentMedication, setCurrentMedication] = useState<Medication>({
    ...blankMedication,
    insertDate: new Date().toISOString(),
  });

  const resetCurrentMedication = () => {
    setCurrentMedication({
      ...blankMedication,
      insertDate: new Date().toISOString(),
    });
    setMedicationToEdit(null);
  };

  const addMedication = () => {
    if (!currentMedication.medicationName) {
      toast.error('Please fill a medication name');
      return;
    }
    const prevState = progressNote.medications ?? [];
    changeProgressNote({
      medications: [...prevState, currentMedication],
    });
    setDialogOpen(false);
    resetCurrentMedication();
  };

  const openEditMedicationDialog = (index: number) => {
    if (progressNote.medications) {
      setMedicationToEdit(index);
      setCurrentMedication(progressNote.medications[index]);
      setDialogOpen(true);
    }
  };

  const editMedication = () => {
    if (medicationToEdit != null) {
      const updatedMedications = progressNote.medications ?? [];
      updatedMedications[medicationToEdit] = currentMedication;
      changeProgressNote({
        medications: updatedMedications,
      });
    }
    setDialogOpen(false);
    resetCurrentMedication();
  };

  const removeMedication = (index: number) => {
    let newMedications = progressNote.medications?.filter((_, mIndex) => {
      return mIndex !== index;
    });
    changeProgressNote({
      medications: newMedications,
    });
  };

  return (
    <ProgressNoteCollapse name="Medications">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.medications && progressNote.medications.length > 0 && (
            <ul className="flex flex-col gap-1 p-2 border rounded border-gray-400">
              {progressNote.medications?.map((medication, index) => {
                return (
                  <li key={index} className="w-full">
                    <div className="flex justify-between w-full">
                      <div className="grid grid-cols-3 w-full">
                        <div>{medication.medicationName}</div>
                        <div>{`${medication.qty ?? ''} ${medication.unit ?? ''}`}</div>
                        <div>{medication.sig ?? ''}</div>
                      </div>
                      <div className="flex gap-2">
                        <p>(</p>
                        <button className="text-blue-500" onClick={() => openEditMedicationDialog(index)}>
                          change
                        </button>
                        <p>|</p>
                        <button className="text-blue-500" onClick={() => removeMedication(index)}>
                          remove
                        </button>
                        <p>)</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="flex justify-end">
            <PrimaryButton
              onClick={() => {
                resetCurrentMedication();
                setDialogOpen(true);
              }}
            >
              Add Medication +
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen}>
          <div className="bg-primary-100">
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>{medicationToEdit !== null ? 'Edit Medication' : 'Add Medication'}</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col gap-5">
                  <TextField
                    label="Medication name *"
                    variant="standard"
                    value={currentMedication.medicationName}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, medicationName: e.target.value })}
                  />
                  <TextField
                    label="Sig"
                    variant="standard"
                    value={currentMedication.sig}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, sig: e.target.value })}
                  />
                  <div className="grid grid-cols-4 gap-2">
                    <TextField
                      label="Qty"
                      variant="standard"
                      type="number"
                      value={currentMedication.qty}
                      onChange={(e) => setCurrentMedication({ ...currentMedication, qty: parseInt(e.target.value) })}
                    />
                    <FormControl variant="standard" className="col-span-2">
                      <InputLabel>Unit</InputLabel>
                      <Select
                        value={currentMedication.unit}
                        onChange={(e) => setCurrentMedication({ ...currentMedication, unit: e.target.value })}
                      >
                        <MenuItem value={'bottle'}>Bottle</MenuItem>
                        <MenuItem value={'tablets'}>Tablets</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Refills"
                      variant="standard"
                      type="number"
                      value={currentMedication.refills}
                      onChange={(e) =>
                        setCurrentMedication({ ...currentMedication, refills: parseInt(e.target.value) })
                      }
                    />
                  </div>
                  <TextField
                    label="Diagnosis (ICD-10)"
                    variant="standard"
                    value={currentMedication.diagnosis}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, diagnosis: e.target.value })}
                  />
                  <div>
                    <input
                      type="checkbox"
                      id="daw"
                      checked={currentMedication.daw}
                      onChange={() => {
                        setCurrentMedication({ ...currentMedication, daw: !currentMedication.daw });
                      }}
                    />
                    <label className="ml-2" htmlFor="daw">
                      Dispense as Written
                    </label>
                  </div>
                </div>

                <div className="flex flex-col gap-5">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Earliest Fill Date"
                      value={
                        currentMedication.earliestFillDate ? new Date(currentMedication.earliestFillDate) : null
                      }
                      onChange={(date: Date | null) => {
                        if (date != null) {
                          setCurrentMedication({ ...currentMedication, earliestFillDate: date.toISOString() });
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <TextField
                    label="Notes to Pharmacy"
                    variant="standard"
                    value={currentMedication.notesToPharmacy}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, notesToPharmacy: e.target.value })}
                  />
                  <TextField
                    label="Notes to Patient"
                    variant="standard"
                    value={currentMedication.notesToPatient}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, notesToPatient: e.target.value })}
                  />
                  <TextField
                    label="Allergic Reactions"
                    variant="standard"
                    value={currentMedication.allergicReactions}
                    onChange={(e) => setCurrentMedication({ ...currentMedication, allergicReactions: e.target.value })}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton onClick={medicationToEdit !== null ? editMedication : addMedication}>
                {medicationToEdit !== null ? 'Save Changes' : 'Add'}
              </PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

export default ProgressNoteMedications;
