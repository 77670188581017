import { PhysicalExam, ReviewOfSystems, VitalSigns } from '../../types';

interface ReviewNamesProps {
  values: ReviewOfSystems | PhysicalExam;
}

const separateNames = (value: string) => {
  switch (value) {
    case 'pulsesHeartSound':
      return 'PULSES AND HEART SOUND';
    default:
      return value.toUpperCase();
  }
};
export const ReviewNames: React.FC<ReviewNamesProps> = ({ values }) => {
  return (
    <ul className="bg-white rounded border border-gray-400  p-4">
      {Object.entries(values).map(([key, reviewArray]) => {
        if (Array.isArray(reviewArray) && reviewArray.length > 0) {
          return (
            <li key={key}>
              <span className="font-semibold">{separateNames(key)}:</span>
              {reviewArray.map((value) => {
                return (
                  <span key={`${value}-${value}`} className="ml-2">
                    {value},
                  </span>
                );
              })}
            </li>
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
};
interface ReviewNamesVitalProps {
  values: VitalSigns;
}
export const ReviewNamesVital: React.FC<ReviewNamesVitalProps> = ({ values }) => {
  return (
    <ul className="bg-white rounded border border-gray-400  p-4">
      {values.temperature && (
        <li>
          <span className="font-semibold">TEMPERATURE:</span>
          <span className="ml-2">{values.temperature.temperature},</span>
          <span className="ml-2">{values.temperature.degree},</span>
          <span className="ml-2">{values.temperature.selected},</span>
        </li>
      )}
      {values.heartRate && (
        <li>
          <span className="font-semibold">HEART RATE:</span>
          <span className="ml-2">{values.heartRate.rate},</span>
          <span className="ml-2">{values.heartRate.selected},</span>
        </li>
      )}
      {values.respirationRate && (
        <li>
          <span className="font-semibold">RESPIRATION RATE:</span>
          <span className="ml-2">{values.respirationRate.rate},</span>
          <span className="ml-2">{values.respirationRate.selected},</span>
        </li>
      )}
      {values.bloodPressure?.map((pressure, index) => {
        return (
          <li key={`pressure-${index}`}>
            <span className="font-semibold">BLOOD PRESSURE {index + 1}:</span>
            <span className="ml-2">{pressure.pressure},</span>
            {pressure.selected.map((value) => {
              return (
                <span key={value} className="ml-2">
                  {value},
                </span>
              );
            })}
          </li>
        );
      })}
      {values.height && (
        <li>
          <span className="font-semibold">HEIGHT:</span>
          <span className="ml-2">{values.height.height},</span>
          {values.height.selected.map((value) => {
            return (
              <span key={value} className="ml-2">
                {value},
              </span>
            );
          })}
        </li>
      )}
      {values.weight && (
        <li>
          <span className="font-semibold">WEIGHT:</span>
          <span className="ml-2">{values.weight.weight},</span>
          {values.weight.selected.map((value) => {
            return (
              <span key={value} className="ml-2">
                {value},
              </span>
            );
          })}
        </li>
      )}
      {values.oxygenation && (
        <li>
          <span className="font-semibold">OXYGENATION:</span>
          <span className="ml-2">{values.oxygenation.oxygenation},</span>
          <span className="ml-2">{values.oxygenation.selected},</span>
        </li>
      )}
      {values.visionOd && (
        <li>
          <span className="font-semibold">VISION OD:</span>
          <span className="ml-2">{values.visionOd.withCorrect && 'With Correction'},</span>
          <span className="ml-2">{values.visionOd.selected},</span>
        </li>
      )}
      {values.visionOs && (
        <li>
          <span className="font-semibold">VISION OS:</span>
          <span className="ml-2">{values.visionOs.withCorrect && 'With Correction'},</span>
          <span className="ml-2">{values.visionOs.selected},</span>
        </li>
      )}
      {values.headCircumference && (
        <li>
          <span className="font-semibold">HEAD CIRCUMFERENCE:</span>
          <span className="ml-2">{values.headCircumference.headCircumference},</span>
          <span className="ml-2">{values.headCircumference.selected},</span>
        </li>
      )}
    </ul>
  );
};
