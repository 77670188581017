import { useState } from 'react';
import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { ProgressNoteProps, PhysicalExam } from '../../../types';
import PrimaryButton from '../../custom/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ProgressNoteCollapse from './ProgressNoteCollapse';
import { ReviewNames } from '../../common/ReviewNames';

const ProgressNotePhysicalExam: React.FC<ProgressNoteProps> = ({ progressNote, changeProgressNote }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [physicalExam, setPhysicalExam] = useState<PhysicalExam>(progressNote.physicalExam ?? {});

  return (
    <ProgressNoteCollapse name="Physical Exam">
      <>
        <div className="flex flex-col background-primary-100 p-4 gap-2">
          {progressNote.physicalExam && <ReviewNames values={progressNote.physicalExam} />}
          <div className="flex">
            <PrimaryButton
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Start Physical Exam
            </PrimaryButton>
          </div>
        </div>
        <Dialog open={dialogOpen} fullScreen className="relative">
          <div
            className="bg-primary-300 h-full"
            style={{
              height: '200%',
            }}
          >
            <div className="px-4 flex justify-between items-center">
              <DialogTitle>Physical Exam</DialogTitle>
              <div className="cursor-pointer" onClick={() => setDialogOpen(false)}>
                <CloseIcon className="opacity-50" />
              </div>
            </div>
            <Divider />
            <DialogContent>
              <div className="flex flex-col w-full">
                <div>Basic</div>
                <div className="grid grid-cols-3 gap-5">
                  <div className="flex flex-col">
                    <PartialData
                      type="constitution"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.constitution ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          constitution: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="hent"
                      physicalExam={physicalExam}
                      onSelect={(value, type) => {
                        let newData;
                        if (type === 'rightEar') {
                          newData = physicalExam.rightEar ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            rightEar: newData,
                          });
                        } else if (type === 'leftEar') {
                          newData = physicalExam.leftEar ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            leftEar: newData,
                          });
                        } else {
                          newData = physicalExam.hent ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            hent: newData,
                          });
                        }
                      }}
                    />
                    <PartialData
                      type="nose"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.nose ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          nose: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="mouthThroat"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.mouthThroat ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          mouthThroat: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="eyes"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.eyes ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          eyes: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="neck"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.neck ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          neck: newData,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <PartialData
                      type="cardioVascular"
                      physicalExam={physicalExam}
                      onSelect={(value, type) => {
                        let newData;

                        if (type === 'cardioRate') {
                          newData = physicalExam.cardioRate ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            cardioRate: newData,
                          });
                        } else if (type === 'cardioRhythm') {
                          newData = physicalExam.cardioRhythm ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            cardioRhythm: newData,
                          });
                        } else {
                          newData = physicalExam.pulsesHeartSound ?? [];
                          if (newData.includes(value)) {
                            newData = newData.filter((str) => str !== value);
                          } else {
                            newData.push(value);
                          }
                          setPhysicalExam({
                            ...physicalExam,
                            pulsesHeartSound: newData,
                          });
                        }
                      }}
                    />
                    <PartialData
                      type="pulmonary"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.pulmonary ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          pulmonary: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="abdominal"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.abdominal ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          abdominal: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="genitourinaryAnorectal"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.genitourinaryAnorectal ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          genitourinaryAnorectal: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="musculoskeletal"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.musculoskeletal ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          musculoskeletal: newData,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <PartialData
                      type="skin"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.skin ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          skin: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="neurologic"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.neurologic ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          neurologic: newData,
                        });
                      }}
                    />
                    <PartialData
                      type="psychiatric"
                      physicalExam={physicalExam}
                      onSelect={(value) => {
                        let newData = physicalExam.psychiatric ?? [];
                        if (newData.includes(value)) {
                          newData = newData.filter((str) => str !== value);
                        } else {
                          newData.push(value);
                        }
                        setPhysicalExam({
                          ...physicalExam,
                          psychiatric: newData,
                        });
                      }}
                    />
                    <div className="flex w-full">{physicalExam && <ReviewNames values={physicalExam} />}</div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setDialogOpen(false)}>Cancel</PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  changeProgressNote({
                    ...progressNote,
                    physicalExam,
                  });
                  setDialogOpen(false);
                }}
              >
                Accept
              </PrimaryButton>
            </DialogActions>
          </div>
        </Dialog>
      </>
    </ProgressNoteCollapse>
  );
};

interface PartialDataProps {
  type: keyof Omit<PhysicalExam, 'comment'>;
  physicalExam: PhysicalExam;
  onSelect: (value: string, type?: keyof Omit<PhysicalExam, 'comment'>) => void;
}

const PartialData: React.FC<PartialDataProps> = ({ type, physicalExam, onSelect }) => {
  const [collapseOpen, setCollapseOpen] = useState(true);
  const data = physicalExam[type] ?? [];

  const BasicCheckbox = ({ name }: { name: string }) => {
    return (
      <li>
        <input id={name} type="checkbox" defaultChecked={data.includes(name)} onClick={() => onSelect(name)} />
        <label className="text-sm ml-1" htmlFor={name}>
          {name}
        </label>
      </li>
    );
  };

  const ButtonCheckbox = ({ name }: { name: string }) => {
    return (
      <li
        className={`flex justify-between px-2 mb-1 ${
          data.includes(name) ? 'bg-primary-500' : 'bg-primary-100'
        } items-center rounded-xl cursor-pointer border border-gray-400`}
        onClick={() => onSelect(name)}
      >
        <div className="font-bold text-right">+</div>
        <div className="text-sm text-center">{name}</div>
        <div className="font-bold">-</div>
      </li>
    );
  };

  const selectData = () => {
    switch (type) {
      case 'constitution':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Alert" />
              <BasicCheckbox name="Normal Weight" />
              <ButtonCheckbox name="Acute Distres" />
              <ButtonCheckbox name="Toxic Appearing" />
            </ul>
            <ul>
              <BasicCheckbox name="Normal Appearance" />
              <BasicCheckbox name="Obese" />
              <ButtonCheckbox name="III-Appearing" />
              <ButtonCheckbox name="Diaphoretic" />
            </ul>
          </div>
        );
      case 'hent':
        return (
          <div className="flex flex-col">
            <div className="font-medium">Head</div>
            <div className="grid grid-cols-2 gap-1">
              <ul>
                <ButtonCheckbox name="Normocephalic" />
                <li className="font-medium">Right Ear</li>
                <li>
                  <input
                    id={'RightEarTMNormal'}
                    type="checkbox"
                    defaultChecked={physicalExam.rightEar?.includes('TM Normal')}
                    onClick={() => onSelect('TM Normal', 'rightEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'RightEarTMNormal'}>
                    {'TM Normal'}
                  </label>
                </li>
                <li>
                  <input
                    id={'RightEarCanal Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.rightEar?.includes('Canal Normal')}
                    onClick={() => onSelect('Canal Normal', 'rightEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'RightEarCanal Normal'}>
                    {'Canal Normal'}
                  </label>
                </li>
                <li>
                  <input
                    id={'RightEarExt Ear Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.rightEar?.includes('Ext Ear Normal')}
                    onClick={() => onSelect('Ext Ear Normal', 'rightEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'RightEarExt Ear Normal'}>
                    {'Ext Ear Normal'}
                  </label>
                </li>
                <li
                  className={`flex justify-between px-2 mb-1 ${
                    physicalExam.rightEar?.includes('Impacted Cerumen') ? 'bg-primary-500' : 'bg-primary-100'
                  } items-center rounded-xl cursor-pointer border border-gray-400`}
                  onClick={() => onSelect('Impacted Cerumen', 'rightEar')}
                >
                  <div className="font-bold text-right">+</div>
                  <div className="text-sm text-center">{'Impacted Cerumen'}</div>
                  <div className="font-bold">-</div>
                </li>
              </ul>
              <ul>
                <ButtonCheckbox name="Atraumatic" />
                <li className="font-medium">Left Ear</li>
                <li>
                  <input
                    id={'LeftEarTMNormal'}
                    type="checkbox"
                    defaultChecked={physicalExam.leftEar?.includes('TM Normal')}
                    onClick={() => onSelect('TM Normal', 'leftEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'LeftEarTMNormal'}>
                    {'TM Normal'}
                  </label>
                </li>
                <li>
                  <input
                    id={'LeftEarCanal Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.leftEar?.includes('Canal Normal')}
                    onClick={() => onSelect('Canal Normal', 'leftEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'LeftEarCanal Normal'}>
                    {'Canal Normal'}
                  </label>
                </li>
                <li>
                  <input
                    id={'LeftEarExt Ear Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.leftEar?.includes('Ext Ear Normal')}
                    onClick={() => onSelect('Ext Ear Normal', 'leftEar')}
                  />
                  <label className="text-sm ml-1" htmlFor={'LeftEarExt Ear Normal'}>
                    {'Ext Ear Normal'}
                  </label>
                </li>
                <li
                  className={`flex justify-between px-2 mb-1 ${
                    physicalExam.leftEar?.includes('Impacted Cerumen') ? 'bg-primary-500' : 'bg-primary-100'
                  } items-center rounded-xl cursor-pointer border border-gray-400`}
                  onClick={() => onSelect('Impacted Cerumen', 'leftEar')}
                >
                  <div className="font-bold text-right">+</div>
                  <div className="text-sm text-center">{'Impacted Cerumen'}</div>
                  <div className="font-bold">-</div>
                </li>
              </ul>
            </div>
          </div>
        );
      case 'cardioVascular':
        return (
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-1">
              <ul>
                <li className="font-medium">Rate</li>
                <li>
                  <input
                    id={'Normal Rate'}
                    type="checkbox"
                    defaultChecked={physicalExam.cardioRate?.includes('Normal Rate')}
                    onClick={() => onSelect('Normal Rate', 'cardioRate')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Normal Rate'}>
                    {'Normal Rate'}
                  </label>
                </li>
                <li>
                  <input
                    id={'Tachycardia'}
                    type="checkbox"
                    defaultChecked={physicalExam.cardioRate?.includes('Tachycardia')}
                    onClick={() => onSelect('Tachycardia', 'cardioRate')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Tachycardia'}>
                    {'Tachycardia'}
                  </label>
                </li>
                <li>
                  <input
                    id={'Bradycardia'}
                    type="checkbox"
                    defaultChecked={physicalExam.cardioRate?.includes('Bradycardia')}
                    onClick={() => onSelect('Bradycardia', 'cardioRate')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Bradycardia'}>
                    {'Bradycardia'}
                  </label>
                </li>
              </ul>
              <ul>
                <li className="font-medium">Rhythm</li>
                <li>
                  <input
                    id={'Regular'}
                    type="checkbox"
                    defaultChecked={physicalExam.cardioRhythm?.includes('Regular')}
                    onClick={() => onSelect('Regular', 'cardioRhythm')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Regular'}>
                    {'Regular'}
                  </label>
                </li>
                <li>
                  <input
                    id={'Irregular'}
                    type="checkbox"
                    defaultChecked={physicalExam.cardioRhythm?.includes('Irregular')}
                    onClick={() => onSelect('Irregular', 'cardioRhythm')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Irregular'}>
                    {'Irregular'}
                  </label>
                </li>
              </ul>
            </div>
            <div className="font-medium">Pulses and Heart Sounds</div>
            <div className="grid grid-cols-2 gap-1">
              <ul>
                <li>
                  <input
                    id={'Pulses Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.pulsesHeartSound?.includes('Pulses Normal')}
                    onClick={() => onSelect('Pulses Normal', 'pulsesHeartSound')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Pulses Normal'}>
                    {'Pulses Normal'}
                  </label>
                </li>
                <li
                  className={`flex justify-between px-2 mb-1 ${
                    physicalExam.pulsesHeartSound?.includes('Murmur') ? 'bg-primary-500' : 'bg-primary-100'
                  } items-center rounded-xl cursor-pointer border border-gray-400`}
                  onClick={() => onSelect('Murmur', 'pulsesHeartSound')}
                >
                  <div className="font-bold text-right">+</div>
                  <div className="text-sm text-center">{'Murmur'}</div>
                  <div className="font-bold">-</div>
                </li>
                <li
                  className={`flex justify-between px-2 mb-1 ${
                    physicalExam.pulsesHeartSound?.includes('Gallop') ? 'bg-primary-500' : 'bg-primary-100'
                  } items-center rounded-xl cursor-pointer border border-gray-400`}
                  onClick={() => onSelect('Gallop', 'pulsesHeartSound')}
                >
                  <div className="font-bold text-right">+</div>
                  <div className="text-sm text-center">{'Gallop'}</div>
                  <div className="font-bold">-</div>
                </li>
              </ul>
              <ul>
                <li>
                  <input
                    id={'Heart Sounds Normal'}
                    type="checkbox"
                    defaultChecked={physicalExam.pulsesHeartSound?.includes('Heart Sounds Normal')}
                    onClick={() => onSelect('Heart Sounds Normal', 'pulsesHeartSound')}
                  />
                  <label className="text-sm ml-1" htmlFor={'Heart Sounds Normal'}>
                    {'Heart Sounds Normal'}
                  </label>
                </li>
                <li
                  className={`flex justify-between px-2 mb-1 ${
                    physicalExam.pulsesHeartSound?.includes('Friction Rub') ? 'bg-primary-500' : 'bg-primary-100'
                  } items-center rounded-xl cursor-pointer border border-gray-400`}
                  onClick={() => onSelect('Friction Rub', 'pulsesHeartSound')}
                >
                  <div className="font-bold text-right">+</div>
                  <div className="text-sm text-center">{'Friction Rub'}</div>
                  <div className="font-bold">-</div>
                </li>
              </ul>
            </div>
          </div>
        );
      case 'nose':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Nose Normal" />
              <ButtonCheckbox name="Congestion" />
            </ul>
            <ul>
              <li>&nbsp;</li>
              <ButtonCheckbox name="Rhinorrhea" />
            </ul>
          </div>
        );
      case 'mouthThroat':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Moist" />
              <BasicCheckbox name="Clear" />
              <ButtonCheckbox name="Exudate" />
            </ul>
            <ul>
              <BasicCheckbox name="Dry" />
              <li>&nbsp;</li>
              <ButtonCheckbox name="Erythema" />
            </ul>
          </div>
        );
      case 'eyes':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="PERRL" />
              <BasicCheckbox name="Conjunctivae Normal" />
              <ButtonCheckbox name="Right Eye Discharge" />
              <ButtonCheckbox name="Scleral Icterus" />
            </ul>
            <ul>
              <BasicCheckbox name="EOM Intact" />
              <li>&nbsp;</li>
              <ButtonCheckbox name="Left Eye Discharge" />
            </ul>
          </div>
        );
      case 'neck':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="ROM Normal" />
              <ButtonCheckbox name="Neck Rigidity" />
              <ButtonCheckbox name="Cerv Adenopathy" />
            </ul>
            <ul>
              <BasicCheckbox name="Supple" />
              <ButtonCheckbox name="Tenderness" />
              <ButtonCheckbox name="Carotid Bruit" />
            </ul>
          </div>
        );
      case 'pulsesHeartSound':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Pulses Normal" />
              <ButtonCheckbox name="Murmur" />
              <ButtonCheckbox name="Gallop" />
            </ul>
            <ul>
              <BasicCheckbox name="Heart Sounds Normal" />
              <ButtonCheckbox name="Friction Rub" />
            </ul>
          </div>
        );
      case 'pulmonary':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Normal Effort" />
              <ButtonCheckbox name="Respiratory Distress" />
              <ButtonCheckbox name="Wheezes" />
              <ButtonCheckbox name="Rales" />
            </ul>
            <ul>
              <BasicCheckbox name="Breath Sounds Normal" />
              <ButtonCheckbox name="Rhonchi" />
              <ButtonCheckbox name="Stridor" />
              <ButtonCheckbox name="Chest Tenderness" />
            </ul>
          </div>
        );
      case 'abdominal':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Flat" />
              <BasicCheckbox name="Bowel Sounds Normal" />
              <ButtonCheckbox name="Distension" />
              <ButtonCheckbox name="Tenderness" />
              <ButtonCheckbox name="Guarding" />
              <ButtonCheckbox name="Right CVA Tender" />
            </ul>
            <ul>
              <BasicCheckbox name="Soft" />
              <li>&nbsp;</li>
              <ButtonCheckbox name="Mass" />
              <ButtonCheckbox name="Hernia" />
              <ButtonCheckbox name="Rebound" />
              <ButtonCheckbox name="Left CVA Tender" />
            </ul>
          </div>
        );
      case 'genitourinaryAnorectal':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Vulva Normal" />
              <ButtonCheckbox name="Guliac Result" />
            </ul>
            <ul>
              <BasicCheckbox name="Rectum Normal" />
              <ButtonCheckbox name="Vaginal Discharge" />
            </ul>
          </div>
        );
      case 'musculoskeletal':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="ROM Normal" />
              <ButtonCheckbox name="Swelling" />
              <ButtonCheckbox name="Deformity" />
              <ButtonCheckbox name="Right Leg Edema" />
            </ul>
            <ul>
              <li>&nbsp;</li>
              <ButtonCheckbox name="Tenderness" />
              <ButtonCheckbox name="Injury" />
              <ButtonCheckbox name="Left Leg Edema" />
            </ul>
          </div>
        );
      case 'skin':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Warm" />
              <ButtonCheckbox name="Jaundice" />
              <ButtonCheckbox name="Bruising" />
              <ButtonCheckbox name="Lesion" />
            </ul>
            <ul>
              <BasicCheckbox name="Dry" />
              <ButtonCheckbox name="Pale" />
              <ButtonCheckbox name="Erythema" />
              <ButtonCheckbox name="Rash" />
            </ul>
          </div>
        );
      case 'neurologic':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="No Neuro Deficit" />
              <BasicCheckbox name="At Baseline" />
              <ButtonCheckbox name="Cranial Nerve Deficit" />
              <ButtonCheckbox name="Weakness" />
              <ButtonCheckbox name="Abnormal Gait" />
            </ul>
            <ul>
              <BasicCheckbox name="Alert and Attentive" />
              <BasicCheckbox name="Disoriented" />
              <ButtonCheckbox name="Sensory Deficit" />
              <ButtonCheckbox name="Coordination Abnl" />
              <ButtonCheckbox name="DTRs Abnormal" />
            </ul>
          </div>
        );
      case 'psychiatric':
        return (
          <div className="grid grid-cols-2 gap-1">
            <ul>
              <BasicCheckbox name="Mood Normal" />
              <BasicCheckbox name="Thought Content Normal" />
            </ul>
            <ul>
              <BasicCheckbox name="Judgement Normal" />
              <BasicCheckbox name="Behavior Normal" />
            </ul>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <div className="font-semibold">{type.charAt(0).toUpperCase() + type.slice(1)}</div>
        <div className="cursor-pointer" onClick={() => setCollapseOpen(!collapseOpen)}>
          {collapseOpen ? <DoDisturbOnOutlinedIcon /> : <AddCircleOutlineOutlinedIcon />}
        </div>
      </div>
      <Collapse in={collapseOpen} unmountOnExit>
        {selectData()}
      </Collapse>
    </div>
  );
};

export default ProgressNotePhysicalExam;
