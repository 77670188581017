import axios from 'axios';
import { Appointment, Note, Patient, ProviderSignupRequest, User } from './types';
import { v4 as uuidv4 } from 'uuid';

const API_URL = 'https://api.filumhealth.com';

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

export async function storeAppointment(appointment: Appointment): Promise<boolean> {
  if (!appointment.startDate) return false;

  appointment.providerId = getUser()!.id;

  try {
    await axios.post(`${API_URL}/appointments`, appointment);
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function updateAppointmentStatus(appointment: Appointment): Promise<boolean> {
  appointment.providerId = getUser()!.id;

  try {
    await axios.patch(`${API_URL}/appointments`, appointment);
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function getAppointments(): Promise<Appointment[]> {
  try {
    return (await axios.get(`${API_URL}/providers/${getUser()!.id}/appointments`)).data;
  } catch (error) {
    console.debug(error);
    return [];
  }
}

export async function storeNote(note: Note): Promise<boolean> {
  try {
    await axios.post(`${API_URL}/notes`, note);
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function getNotesByPatientId(patientId: string): Promise<Note[]> {
  try {
    return (await axios.get(`${API_URL}/notes/${patientId}`)).data;
  } catch (error) {
    console.debug(error);
    return [];
  }
}

export async function getPatientById(patientId: string): Promise<Patient | null> {
  try {
    return (await axios.get(`${API_URL}/patients/${patientId}`)).data;
  } catch (error) {
    console.debug(error);
    return null;
  }
}

export async function storePatient(patient: Patient): Promise<boolean> {
  try {
    patient.patientId = uuidv4();
    patient.providerId = getUser()!.id;
    await axios.post(`${API_URL}/patients`, patient);
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function getBlob(key: string, path: string): Promise<string> {
  try {
    if (!key) return 'please provide blob key';
    const res = await axios.get(`${API_URL}/blobs?key=${path}/${key}`, {
      responseType: 'blob',
      headers: {
        Accept: '*/*',
      },
    });
    return URL.createObjectURL(res.data);
  } catch (error) {
    console.debug(error);
    return 'error';
  }
}

export async function uploadBlob(blob: File, path: string): Promise<string | null> {
  try {
    const fileNameParts = blob.name.split('.');
    const ext = fileNameParts[fileNameParts.length - 1];
    const key = `${uuidv4()}.${ext}`;

    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = async (event) => {
      await axios.put(`${API_URL}/blobs?key=${path}/${key}`, event.target!.result, {
        headers: {
          Authorization: sessionStorage.getItem('token'),
          'Content-Type': blob.type,
        },
      });
    };
    return key;
  } catch (error) {
    console.debug(error);
    return null;
  }
}

export async function confirmProviderVerification(
  username: string,
  code: string,
  registrationCode: string
): Promise<boolean> {
  try {
    const res = await axios.post(`${API_URL}/providers/confirm-signup`, {
      username,
      code,
      registrationCode,
    });
    return res.data === 'success';
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function changeTempPassword(
  username: string,
  tempPassword: string,
  newPassword: string
): Promise<boolean> {
  try {
    const res = await axios.post(`${API_URL}/patients/change-temp-password`, {
      username,
      tempPassword,
      newPassword,
    });
    return res.data === 'success';
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function createProvider(provider: ProviderSignupRequest): Promise<boolean> {
  try {
    const res = await axios.post(`${API_URL}/providers/signup`, provider);
    return res.data === 'success';
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function getPatientIdByEmail(email: string): Promise<string | null> {
  try {
    const res: Partial<Patient> = (await axios.get(`${API_URL}/patients/exists/${email}`)).data;
    if (res.patientId) return res.patientId;
  } catch (error) {
    console.debug(error);
  }
  return null;
}

export async function invitePatientToFilum(patientEmail: string): Promise<boolean> {
  try {
    return (await axios.get(`${API_URL}/patients/invite/${patientEmail}`)).data === 'success';
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function login(username: string, password: string, whichLogin: string): Promise<string> {
  try {
    const res = (
      await axios.post(`${API_URL}/${whichLogin}s/login`, {
        username,
        password,
      })
    ).data;
    if (res !== 'NEW_PASSWORD_REQUIRED') sessionStorage.setItem('token', res);
    return res;
  } catch (error) {
    console.debug(error);
    return 'error';
  }
}

export async function getAllPatients(): Promise<Patient[]> {
  try {
    const allPatients = (await axios.get(`${API_URL}/providers/${getUser()!.id}/patients`)).data;
    allPatients.sort((a: Patient, b: Patient) => new Date(b.insertDate!).getTime() - new Date(a.insertDate!).getTime());
    return allPatients;
  } catch (error) {
    console.debug(error);
    return [];
  }
}

export function getUser(): User | null {
  const existingUser = sessionStorage.getItem('user');
  if (existingUser) return JSON.parse(existingUser);

  const token = sessionStorage.getItem('token');
  if (!token) return null;

  try {
    const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const decodedToken = JSON.parse(atob(base64));

    const user: User = {
      id: decodedToken.sub,
      email: decodedToken.email,
      firstName: decodedToken.given_name,
      lastName: decodedToken.family_name,
      isPatient: decodedToken.iss.includes('DH2XKB7t0'),
    };

    sessionStorage.setItem('user', JSON.stringify(user));

    return user;
  } catch (error) {
    console.debug(error);
    return null;
  }
}
